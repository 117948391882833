import apiInstance from "./serviceMaster";

export const generateModelTableId = (id) => {
    if (id && parseInt(id) > 0) {
        if (parseInt(id) < 10) {
            return `mdl-00${id}`
        }
        else if (parseInt(id) >= 10 && parseInt(id) < 100) {
            return `mdl-0${id}`
        }
        else {
            return `mdl-${id}`
        }
    }

}

const tablesUrl = "mdl/tables";
const sourceMasterUrl = "mdl/source/master";
const datasetsUrl = "mdl/datasets";
const roleUrl = "mdl/roles";

export const getDataSourceTableListService = (obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return apiInstance.get(`${tablesUrl}${queryParams}`)
    }
    catch(e){
        return e;
    } 
}

export const getTableInformationService = (id) => {
    try{
        return apiInstance.get(`${tablesUrl}/${id}`)
    }
    catch(e){
        return e;
    } 
}

export const getTableSectionsInformationService = (id, section, obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return apiInstance.get(`${tablesUrl}/${id}/${section}${queryParams}`)
    }
    catch(e){
        return e;
    } 
}

export const updateTableEntitiesInformationService = (id, obj) => {
    try{
        return apiInstance.put(`${tablesUrl}/${id}`, obj)
    }
    catch(e){
        return e;
    } 
}

export const deleteTableColumnService = (id, columnId) => {
    try{
        return apiInstance.delete(`${tablesUrl}/${id}/column/${columnId}`)
    }
    catch(e){
        return e;
    } 
}

export const deleteTableExampleService = (id, exampleId) => {
    try{
        return apiInstance.delete(`${tablesUrl}/${id}/example/${exampleId}`)
    }
    catch(e){
        return e;
    } 
}

export const getDataSourceInfoService = (obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return apiInstance.get(`${sourceMasterUrl}${queryParams}`)
    }
    catch(e){
        return e;
    } 
}

export const addNewTableService = (obj) => {
    try{
        return apiInstance.post(`${tablesUrl}/sync`, obj)
    }
    catch(e){
        return e;
    } 
}

export const getModelListService = (obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return apiInstance.get(`${tablesUrl}/models${queryParams}`)
    }
    catch(e){
        return e;
    } 
}

export const getUnAddedDatasetsService = (obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return apiInstance.get(`${datasetsUrl}${queryParams}`)
    }
    catch(e){
        return e;
    } 
}

export const syncDatasetColumnsService = (id) => {
    try{
        return apiInstance.get(`${tablesUrl}/${id}/sync`)
    }
    catch(e){
        return e;
    } 
}

export const getRoleBasedModelsService = () => {
    try{
        return apiInstance.get(roleUrl)
    }
    catch(e){
        return e;
    } 
}