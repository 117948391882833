import React, { useContext, useEffect, useState } from "react";
import AIContext from "../../services/context/AIAssistance/aiContext";
import { AuthContext } from "../../services/context/Auth/authContext";
import { getAppBehavior } from "../../services/authService";
import { themeIcons } from "../../services/commonService";
import { KairosAIModalBodyComponent } from "./kairosModalBody";


export const KairosAIModalComponent = () => {

    const { aiModalType, setAIModalType } = useContext(AIContext);
    const { applicableApps } = useContext(AuthContext)

    const [kairosIcon, setKairosIcon] = useState('')
    const [showMinimizeIcon, setShowMinimizeIcon] = useState(true);
    const [showMaximizeIcon, setShowMaximizeIcon] = useState(false);

    const modalMinimize = () => {
        setShowMaximizeIcon(true)
        setShowMinimizeIcon(false)
        let body = document.getElementById('approot');
        body.classList.add('overflow-auto')
        let modalElement = document.getElementById('kairosAIModal');
        modalElement.classList.add('bl-minimize-modal')
        let modalContent = document.getElementById('kairosAIModalContent');
        modalContent.classList.add('bl-minimize-content')
        let modalBody = document.getElementById('kairosAIModalBody');
        modalBody.classList.add('d-none')
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.add('d-none')
    }

    const modalMaximize = () => {
        setShowMaximizeIcon(false);
        setShowMinimizeIcon(true)
        let body = document.getElementById('approot');
        body.classList.remove('overflow-auto')
        let modalElement = document.getElementById('kairosAIModal');
        modalElement.classList.remove('bl-minimize-modal')
        let modalContent = document.getElementById('kairosAIModalContent');
        modalContent.classList.remove('bl-minimize-content')
        let modalBody = document.getElementById('kairosAIModalBody');
        modalBody.classList.remove('d-none')
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.remove('d-none')
    }

    const modalClose = () => {
        setAIModalType('')
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.remove('d-none')
    }

    useEffect(() => {
        modalMaximize()
    }, [aiModalType])

    useEffect(() => {
        getAppThemeImages()
    }, [getAppBehavior])

    const getAppThemeImages = () => {
        const appThemes = getAppBehavior();
        if (appThemes && appThemes !== "undefined") {
            const themeName = JSON.parse(appThemes).themeName;
            const kairos_icon = themeIcons(`${themeName}_K_Icon`);
            setKairosIcon(kairos_icon)
        }
    }

    return (
        <div className="modal fade aiModal p-0 overflow-hidden" id="kairosAIModal" data-backdrop={false} data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="kairosAIModalLabel" aria-hidden="true">
            <div className="modal-dialog mw-100 m-0" role="document">
                <div id="kairosAIModalContent" className="modal-content bl-modal-context min-vh-100">
                    {
                        showMaximizeIcon ? <div className="modal-header p-2">
                            <h6 className="mb-0 ml-2">
                                <img className="img-fluid bl-h-1-25rem mr-2" src={kairosIcon} />
                                AI Assistant
                            </h6>
                        </div> : null
                    }
                    <div className="modal-header d-flex justify-content-between">
                        <h6 className="mb-0">Modal Title</h6>
                        <div className="d-flex justify-content-between align-items-center">
                            {
                                showMinimizeIcon ? <i className="fa fa-1-5x fa-minus fa-fw mr-2 cp d-none" onClick={() => modalMinimize()}></i> : null
                            }
                            {
                                showMaximizeIcon ? <i className="fa fa-1-5x fa-window-maximize fa-fw mr-2 cp modalMinimize" onClick={() => modalMaximize()}></i> : null
                            }
                            {
                                applicableApps && applicableApps.length === 1 && applicableApps[0].appName == "KAIROS" ? null :
                                    <i className="fa fa-1-5x fa-times fa-fw cp" onClick={() => modalClose()} data-dismiss="modal" aria-label="Close"></i>
                            }

                        </div>
                    </div>
                    <div id="kairosAIModalBody" className="modal-body">
                        <KairosAIModalBodyComponent/>
                    </div>
                </div>
            </div>
        </div>
    )
}