import React, { useContext, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { SpeakerBriefContext } from "../../services/context/speakerBriefContext";
import { AnalyzeCompanyContext } from "../../services/context/analyzeCompanyContext";
import { getEventStatusService, getSBEventListService, getSBModelsService } from "../../services/speakerBriefService";


export const SpeakerBriefIndexComponent = () => {

    const { eventList, setEventList, viewType, setIsLoading, setSBModels } = useContext(SpeakerBriefContext)
    const { analyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const location = useLocation()

    useEffect(() => {
        getSBModels(1)
    }, [])

    useEffect(() => {
        fetchEventList();
    }, [analyzedCompanyInfo, viewType]);

    const fetchEventList = async () => {
        setIsLoading(true)
        setEventList([]);
        setTimeout(() => {
            getEventList(1);
        }, 300)
    };

    const getEventList = async (page) => {
        const limit = 30;
        const obj = {
            page: page, limit: limit, publishedStatus: viewType === "ALL" ? null : viewType
        }
        const resp = await getSBEventListService(obj);
        getEventListResponse(page, resp, limit);
        setIsLoading(false)
    };

    const getEventListResponse = (page, resp, limit) => {
        if (resp && resp.success) {
            const newEventList = resp.data;
            setEventList((event) => [...event, ...newEventList]);
            if (resp.data.length >= limit) {
                getEventList(page + 1);
            }
        }
    };

    const getSBModels = async (page) => {
        const limit = 10;
        const obj = {
            page: page, limit: limit
        }
        const resp = await getSBModelsService(obj)
        if (resp && resp.success) {
            const models = resp.data;
            setSBModels((model) => [...model, ...models]);
            if (resp.data.length >= limit) {
                getSBModels(page + 1);
            }
        }
    }

    const isDashboardActive = useRef(false);
    const timeoutRef = useRef(null);

    useEffect(() => {
        const pathArray = location.pathname.split('/');
        isDashboardActive.current = pathArray && pathArray[2] === 'dashboard';

        if (isDashboardActive.current && eventList && eventList.length) {
            const processingEvent = eventList.find(
                event => event.processingStatus.toLowerCase() === 'pending'
            );
            if (processingEvent) {
                getEventStatusById(processingEvent.eventId);
            }
        }

        return () => {
            isDashboardActive.current = false;
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [eventList, location.pathname]);

    const getEventStatusById = async (id) => {
        try {
            const pathArray = location.pathname.split('/');
            isDashboardActive.current = pathArray && pathArray[2] === 'dashboard';

            if (isDashboardActive.current) {
                const resp = await getEventStatusService(id);
                if (resp && resp.success) {
                    const eventInfo = resp.data;
                    if (eventInfo && eventInfo.processingStatus.toLowerCase() === 'completed') {
                        fetchEventList();
                    } else {
                        timeoutRef.current = setTimeout(() => {
                            getEventStatusById(id);
                        }, 3000);
                    }
                }
            }
        } catch (e) {
        }
    };

    return (
        <Outlet />
    )
}