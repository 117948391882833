import React, { useState } from 'react';
import AIContext from './aiContext';

const AIAssistanceContextProvider = ({ children }) => {

  const [aiModalType, setAIModalType] = useState('DEFAULT');
  const [companyProfileWidget, setCompanyProfileWidget] = useState(null);
  const [rolesModelId, setRolesModelId] = useState("")
  const [rolesModelName, setRolesModelName] = useState("")

  return (
    <AIContext.Provider value={{ aiModalType, setAIModalType, companyProfileWidget, setCompanyProfileWidget, rolesModelId, setRolesModelId, rolesModelName, setRolesModelName }}>
      {children}
    </AIContext.Provider>
  );
};

export default AIAssistanceContextProvider;