import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import AIContext from "../../services/context/AIAssistance/aiContext";
import { getAppBehavior, getRoleBasedAppService } from "../../services/authService";
import applicationApps from "./../../data/Menu/apps.json";
import { AuthContext } from "../../services/context/Auth/authContext";
import pdfMenu from "./../../data/PDF/subMenu.json";
import { appendThemeProperty, themeIcons } from "../../services/commonService";
import ThemeContext from "../../services/context/Theme/themeContext";

export const MenuComponent = () => {

    const nav = useNavigate();
    const location = useLocation();
    const { breadcrumbMenu } = useContext(BreadcrumbContext)
    const { setAIModalType } = useContext(AIContext)
    const { setApplicableApps } = useContext(AuthContext)
    const { theme, applyThemeProperty } = useContext(ThemeContext)
    const [companySmallLogo, setCompanySmallLogo] = useState('')
    const [kairosBLogo, setKairosBLogo] = useState('')
    const [apps, setApps] = useState([])
    const [activeTab, setActiveTab] = useState('dashboard')


  useEffect(() => {
    applyTheme()
    const events = ["click"];
    events.forEach((event) => window.addEventListener(event, applyTheme));

    return () => {
      events.forEach((event) => window.removeEventListener(event, applyTheme));
    };
  }, [location.pathname, apps]);

  useEffect(() => {
    getAppThemeImages()
  }, [theme])

  const applyTheme = () => {
    applyThemeProperty() 
  }

    const openSubMenu = () => {
        let isMobile = navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i);
        let element1 = document.getElementById("vistaHeader");
        let element2 = document.getElementById("content-wrapper");
        let element3 = document.getElementById("submenucontent");
        const componentHeader = document.getElementById('componentHeader');
        if (isMobile) {
            if (element1 && !element1.classList.contains('collapsed-header-margin-sub-menu')) {
                element1.classList.add("mob-collapsed-header-margin-sub-menu");
            }
            if (element2 && !element2.classList.contains('collapsed-content-margin-sub-menu')) {
                element2.classList.add("mob-collapsed-content-margin-sub-menu");
            }
            if (element3 && !element3.classList.contains('mob-sub-menu')) {
                element3.classList.add('mob-sub-menu');
            }
            if (componentHeader && !componentHeader.classList.contains('collapsed-header-margin-sub-menu')) {
                componentHeader.classList.add("mob-collapsed-header-margin-sub-menu");
            }
        }
        else {
            if (element1 && !element1.classList.contains('collapsed-header-margin-sub-menu')) {
                element1.classList.add("collapsed-header-margin-sub-menu");
            }

            if (element2 && !element2.classList.contains('collapsed-content-margin-sub-menu')) {
                element2.classList.add("collapsed-content-margin-sub-menu");
            }
            if (componentHeader && !componentHeader.classList.contains('collapsed-header-margin-sub-menu')) {
                componentHeader.classList.add("collapsed-header-margin-sub-menu");
            }
        }
        if (element3 && element3.classList.contains('close')) {
            element3.classList.remove("close")
        }
    }

    const closeSubMenu = () => {
        let isMobile = navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i);
        let element1 = document.getElementById("vistaHeader");
        let element2 = document.getElementById("content-wrapper");
        let element3 = document.getElementById("submenucontent");
        const componentHeader = document.getElementById('componentHeader');
        if (isMobile) {
            if (element1 && element1.classList.contains('collapsed-header-margin-sub-menu')) {
                element1.classList.remove("mob-collapsed-header-margin-sub-menu");
            }
            if (element2 && element2.classList.contains('collapsed-content-margin-sub-menu')) {
                element2.classList.remove("mob-collapsed-content-margin-sub-menu");
            }
            if (element3 && element3.classList.contains('mob-sub-menu')) {
                element3.classList.remove('mob-sub-menu');
            }
            if (componentHeader && componentHeader.classList.contains('collapsed-header-margin-sub-menu')) {
                componentHeader.classList.remove("mob-collapsed-header-margin-sub-menu");
            }
        }
        else {
            if (element1 && element1.classList.contains('collapsed-header-margin-sub-menu')) {
                element1.classList.remove("collapsed-header-margin-sub-menu");
            }

            if (element2 && element2.classList.contains('collapsed-content-margin-sub-menu')) {
                element2.classList.remove("collapsed-content-margin-sub-menu");
            }
            if (componentHeader && componentHeader.classList.contains('collapsed-header-margin-sub-menu')) {
                componentHeader.classList.remove("collapsed-header-margin-sub-menu");
            }
        }
        if (element3 && !element3.classList.contains('close')) {
            element3.classList.add("close")
        }
    }

    useEffect(() => {
        getRoleBasedApp()
    }, [kairosBLogo])

    useEffect(() => {
        mainPageContent();
    }, [location])

    const getAppThemeImages = () => {
        const appThemes = getAppBehavior();
        if (appThemes && appThemes !== "undefined") {
            const themeName = JSON.parse(appThemes).themeName;
            const kairos_b_logo = themeIcons(`${themeName}_K_B_Icon`);
            const comp_small_Logo = themeIcons(`${themeName}_Small_Logo`);
            setKairosBLogo(kairos_b_logo)
            setCompanySmallLogo(comp_small_Logo)
        }
    }

    useEffect(() => {
        if (breadcrumbMenu && breadcrumbMenu.length) {
            if (breadcrumbMenu[0].hasSubMenu) {
                openSubMenu();
            }
            else {
                closeSubMenu()
            }

        }
    }, [breadcrumbMenu])


    useEffect(() => {

    }, [activeTab])

    const getFirstPdfCompany = () => {
        return pdfMenu.find(pdf => pdf.isActive && !pdf.isDisabled).slug;
    }

    const getRoleBasedApp = async () => {
        const resp = await getRoleBasedAppService();
        if (resp && resp.success && resp.data && resp.data.length) {
            const appList = resp.data;

            const matchedApps = applicationApps.filter(app =>
                appList.some(applicableApp => applicableApp.appName === app.appName)
            ).map(app => ({
                ...app,
                displayName: appList.find(applicableApp => applicableApp.appName === app.appName).displayName,
                image: app.appName === "KAIROS" ? kairosBLogo : "",
                path: app.appName === "PDF_VIEWER" ? `/pdf/${getFirstPdfCompany()}` : app.path,
                position: appList.find(applicableApp => applicableApp.appName === app.appName).priority
            }));
            matchedApps.sort((a, b) => a.position - b.position)
            setApps(matchedApps)
            setApplicableApps(matchedApps)
        }
    }


    const mainPageContent = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 1) {
            setActiveTab(pathArray[1])
        }
    }

    useEffect(() => {
        getStatus()
    }, [])

    const getStatus = async () => {
        // let stat = await getAPIStatus();
    }

    const applyThemeOnActiveMenu = () => {
        const adminElement = document.getElementById('adminmenu');
        if(adminElement){
            adminElement.removeAttribute('style')
        }
        for (let i = 0; i < apps.length; i++) {
            const element = document.getElementById(`mainmenuitem${i}`);
            if (element) {
                element.removeAttribute('style')
            }
        }
        const appThemes = getAppBehavior();
        if (theme) {
            appendThemeProperty(theme)
        }
        else if (appThemes && appThemes !== "undefined") {
            const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
            if (appTheme) {
                appendThemeProperty(appTheme)
            }
        }
    }

    return (
        <ul className="navbar-nav navbar-light sidebar accordion fixed-top toggled mainmenu d-print-none" id="accordionSidebar" >

            <button className="sidebar-brand d-flex align-items-center justify-content-center cp">
                <div className="sidebar-brand-icon">
                    <img src={companySmallLogo} height="40px" alt="logo" />
                </div>
            </button>

            <hr className="sidebar-divider" />
            {
                apps.map((app, i) => {
                    return (
                        app.isInBottom ? <div key={`application-apps-footer-${i}`}  className="footer">
                        <hr className="sidebar-divider" />
                        <li className={activeTab === app.slug ? 'nav-item mb-0 active' : 'nav-item mb-0'}>
                            <button className="nav-link" onClick={() => { nav(app.path); setActiveTab(app.slug); applyThemeOnActiveMenu() }}>
                                <div className="active-menu" id={`adminmenu`}>
                                    <i className={`fa ${app.iconSize} ${app.icon}`}></i>
                                </div>
                                <span>{app.displayName}</span>
                            </button>
                        </li>
                    </div> : 
                        <li key={`application-apps-${i}`} className={activeTab === app.slug ? 'nav-item active' : 'nav-item'}>
                            {
                                app.isModal ? (
                                    <button className="nav-link" onClick={() => setAIModalType('DEFAULT')} data-toggle="modal" data-target="#aimodalbox">
                                        <div className="active-menu">
                                            {
                                                app.image ? <img src={app.image} alt="menu-icon" className="img-fluid mt-n1" /> : <i className={`fa ${app.iconSize} ${app.icon}`} aria-hidden="true"></i>
                                            }
                                        </div>
                                        <span>{app.displayName}</span>
                                    </button>
                                ) : (                                   
                                    <button className="nav-link" onClick={() => { nav(app.path); setActiveTab(app.slug); applyThemeOnActiveMenu() }}>
                                        <div className={`active-menu`} id={`mainmenuitem${i}`}>
                                            {
                                                app.image ? <img src={app.image} alt="menu-icon" className="img-fluid mt-n1" /> : <i className={`fa ${app.iconSize} ${app.icon}`} aria-hidden="true"></i>
                                            }
                                        </div>
                                        <span>{app.displayName}</span>
                                    </button>
                                )
                            }
                        </li>
                    )
                })
            }
            {/* <div className="footer">
                <hr className="sidebar-divider" />
                <li className={activeTab === 'admin' ? 'nav-item mb-0 active' : 'nav-item mb-0'}>
                    <button className="nav-link" onClick={() => { nav('/admin/testing-harness'); setActiveTab('admin'); applyThemeOnActiveMenu() }}>
                        <div className="active-menu" id={`adminmenu`}>
                            <i className="fa fa-1x fa-cog"></i>
                        </div>
                        <span>Admin Console</span>
                    </button>
                </li>
            </div> */}
            {/* <li className={activeTab === 'dashboard' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/dashboard'); setActiveTab('dashboard') }}>
                    <div className="active-menu">
                        <i className="fa fa-1x fa-home"></i>
                    </div>
                    <span>Home</span>
                </button>
            </li>
            <li className={activeTab === 'analytics' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/analytics/overview'); setActiveTab('analytics') }}>
                    <div className="active-menu">
                        <i className="fa fa-pie-chart" aria-hidden="true"></i>
                    </div>
                    <span>Analytics</span>
                </button>
            </li>
            <li className="nav-item">
                <button className="nav-link" onClick={() => setAIModalType('DEFAULT')} data-toggle="modal" data-target="#aimodalbox">
                    <div className="active-menu">
                        <i className="fa fa-gg-circle" aria-hidden="true"></i>
                    </div>
                    <span>AI</span>
                </button>
            </li> */}
        </ul>
    )
}