import React, { useContext, useEffect, useState } from "react";
import testIcon from "./../../../../assets/icons/testIcon.svg";
import testingFormLabel from "../../../../utils/property-file/testingHarness/formsLabel.json";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbContext from "../../../../services/context/Breadcrumb/breadcrumbContext";
import { RunSummaryComponent } from "./runSummary";
import { RunHistoryComponent } from "./runHistory";
import { ViewTestPlanComponent } from "./viewPlan";
import { runTestPlanService } from "../../../../services/testService";
import { getAIModelService } from "../../../../services/aiService";
import { toastInfo, toastSuccess } from "../../../../services/notifyService";
import reportForm from "../../../../utils/property-file/reports/resourceForm.json";
import buttonsLabel from "./../../../../utils/property-file/buttons-label.json";


export const TestPlanInfoComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const nav = useNavigate()
    const location = useLocation()

    const [planName, setPlanName] = useState('')
    const [testPlanId, setTestPlanId] = useState('')
    const [viewType, setViewType] = useState('DETAILS')
    const [selectedQuestions, setSelectedQuestions] = useState([])
    const [testPlanRunId, setTestPlanRunId] = useState('')

    useEffect(() => {
        console.log("location.state ====>", location.state);
        setViewType(location.state && location.state.viewType ? location.state.viewType : "DETAILS")
        setTestPlanRunId(location.state && location.state.testPlanRunId ? location.state.testPlanRunId : '')
        const pathName = location.pathname.split('/');
        const planId = pathName[pathName.length - 1];
        setTestPlanId(planId)
        getAIModels()
        setBreadcrumbMenu([
            { slug: '', menuName: 'Admin', isActive: true, hasSubMenu: true },
            { slug: `/admin/testing-harness`, menuName: "Testing Harness", isActive: false, hasSubMenu: true },
            { slug: `/admin/testing-harness/summary/${planId}`, menuName: "Summary", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname, location.state])

    const getPlanNameFromChild = (data) => {
        setPlanName(data);
    };

    const [defaultModel, setDefaultModel] = useState({
        aiModelId: null, displayName: '', model: ''
    })

    const getAIModels = async () => {
        const resp = await getAIModelService();
        if (resp && resp.success) {
            const defaultModel = resp.data.find(model => model.default);
            if (defaultModel) {
                setDefaultModel(defaultModel)
            }
        }
    }

    const getSelectedQuestion = (questions) => {
        console.log("getSelectedQuestion ===>", questions);
        setSelectedQuestions(questions)
    }

    const runTestPlan = async () => {
        const obj = {
            testPlanMasterId: testPlanId, aiModelId: defaultModel.model, 
            testCaseIds: selectedQuestions
        }
        console.log("Check run cases ====>", obj);
        toastInfo("Test Job is in progress, please wait until job gets completed!");
        window.$("#testRunAlertModal").modal("hide");
        nav('/admin/testing-harness', { state: { jobExecuted: true, testPlanId: testPlanId }})
        const resp = await runTestPlanService(obj)
        if (resp && resp.success) {

        }
    }

    const testRunConfirmationAlertContent = () => {
        return (
            <div className="modal fade" id="testRunAlertModal" tabIndex="-1" aria-labelledby="testRunAlertModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="testRunAlertModalLabel">{reportForm.RUN.TITLE}</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {reportForm.RUN.INFO} "<b>{planName}</b>"
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-primary" type="button" data-dismiss="modal">{buttonsLabel.CANCEL}</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => runTestPlan()}>{buttonsLabel.YES}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="d-sm-flex align-items-center justify-content-between mb-2 bl-action-btn-sec">
                    <div className="d-inline-flex flex-row bl-btn-grp-fixed bl-right-unset">
                        <button onClick={() => nav('/admin/testing-harness')} className="d-sm-inline-block btn btn-sm btn-outline-primary"><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                    </div>
                    <div className="d-inline-flex flex-row bl-btn-grp-fixed">
                        {viewType === 'DETAILS' ? <button data-toggle="modal" data-target="#testRunAlertModal" className="d-sm-inline-block btn btn-sm btn-outline-primary mr-2"><i className="fa fa-play mr-1" aria-hidden="true"></i> Run Test Plan</button> : null}
                        {viewType === 'DETAILS' ? <button onClick={() => nav(`/admin/testing-harness/manage/${testPlanId}`)} className="d-sm-inline-block btn btn-sm btn-primary"><i className="fa fa-pencil mr-1" aria-hidden="true"></i> Edit Test Plan</button> : null}
                    </div>

                </div>
                <div className="row" id="bio">
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <div className="sidebar-brand-icon mb-2">
                            <img src={testIcon} height="80px" />
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <h1 className="h3 text-gray-800">{testingFormLabel.TEST_PLAN}{planName}</h1>
                    </div>
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center mb-3">
                        <div className="d-flex">
                            <span className={`badge bl-chip-btn cp mr-2 ${viewType === 'DETAILS' ? 'active' : ''}`} onClick={() => setViewType('DETAILS')}>Test Plan Details</span>
                            <span className={`badge bl-chip-btn cp ${viewType === 'SUMMARY' ? 'active' : ''}`} onClick={() => setViewType('SUMMARY')}>Last Run Summary</span>
                            {/* <span className={`badge bl-chip-btn cp ${viewType === 'HISTORY' ? 'active' : ''}`}>Run History</span> */}
                        </div>
                    </div>
                    {
                        viewType === 'DETAILS' ? <ViewTestPlanComponent getPlanName={getPlanNameFromChild} getSelectedQuestion={getSelectedQuestion} /> : viewType === 'SUMMARY' ? <RunSummaryComponent getPlanName={getPlanNameFromChild} testPlanRunId={testPlanRunId} /> : <RunHistoryComponent />
                    }

                </div>

            </div>
            {testRunConfirmationAlertContent()}
        </div>
    )
}