import apiInstance from "./serviceMaster";

const aiQueryURL = "chat/ask/question";
const visualizeResultURL = "chat/visualize/result";
const explainNoRecordURL = "chat/explain-no-record";
const threadsURL = "chat/threads";
const threadURL = "chat/thread";
const historyURL = "chat/history/";
const suggestiveQuestionURL = "chat/suggestive-question";
const feedbackURL = "chat/feedback";
const threadCountURL = "chat/thread/count";
const sourceSuggestiveQuestionURL = "chat/suggestive/question/for/source";
const aiModelURL = "application/model"

export async function askQueryService(obj){
    try{
        return await apiInstance.post(aiQueryURL, obj)
    }
    catch(e){
        return e;
    }
}

export const visualizeResultService = async(obj) => {
    try{
        return await apiInstance.post(visualizeResultURL, obj)
    }
    catch(e){
        return e;
    }
}

export async function getChatHistoryThreadService(obj){
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${threadsURL}${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export async function getChatHistoryByThreadService(obj){
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${historyURL}${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export async function deleteChatHistoryByThreadService(obj){
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${threadURL}/delete${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export const getSuggestiveQuestionService = async() => {
    try{
        return await apiInstance.get(suggestiveQuestionURL)
    }
    catch(e){
        return e;
    }
}

export const getSourceBasedSuggestiveQuestionService = async() => {
    try{
        return await apiInstance.get(sourceSuggestiveQuestionURL)
    }
    catch(e){
        return e;
    }
}

export const addUserFeedbackService = async(obj) => {
    try{
        return await apiInstance.post(feedbackURL, obj)
    }
    catch(e){
        return e;
    }
}

export const getAIModelService = async() => {
    try{
        return await apiInstance.get(aiModelURL)
    }
    catch(e){
        return e;
    }
}

export const getThreadCountService = async(obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${threadCountURL}${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export const getActiveUserListForAdminService = async (obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`/chat/user/list${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export const explainForNoRecordService = async(obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${explainNoRecordURL}${queryParams}`)
    }
    catch(e){
        return e;
    }
}