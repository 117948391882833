import React, { useContext, useEffect, useState } from "react";
import { getAppBehavior } from "../../services/authService";
import { convertCamelCaseToSeparateWords, themeIcons } from "../../services/commonService";
import { AuthContext } from "../../services/context/Auth/authContext";
import { ColumnChart } from "../Graph/columnChart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCurrency } from "../../utils/validation";

export const KairosAIModalBodyComponent = () => {

    const { userMail, loggedUserMail } = useContext(AuthContext)
    const [kairosIcon, setKairosIcon] = useState('')
    const [kairosLogo, setKairosLogo] = useState('')
    const [companySmallLogo, setCompanySmallLogo] = useState('')
    const [selectedSource, setSelectedSource] = useState([])
    const [userQuestion, setUserQuestion] = useState('')

    const [categoryField, setCategoryField] = useState("");
    const [incomeSeries, setIncomeSeries] = useState([]);
    const [incomeTableInfo, setIncomeTableInfo] = useState([]);
    const [incomeTableHeader, setIncomeTableHeader] = useState([]);
    const [incomePeriodData, setIncomePeriodData] = useState([]);

    useEffect(() => {
        getAppThemeImages()
    }, [getAppBehavior])

    useEffect(() => {
        getIncomeStatementData()
    }, [])

    const getAppThemeImages = () => {
        const appThemes = getAppBehavior();
        if (appThemes && appThemes !== "undefined") {
            const themeName = JSON.parse(appThemes).themeName;
            const kairos_logo = themeIcons(`${themeName}_K_Logo`);
            const kairos_icon = themeIcons(`${themeName}_K_Icon`);
            const appSmallLogo = themeIcons(`${themeName}_Small_Logo`);
            setKairosIcon(kairos_icon)
            setKairosLogo(kairos_logo)
            setCompanySmallLogo(appSmallLogo)
        }
    }

    const getQuestionInputByUser = (e, question) => {
        e.preventDefault();
        if (loggedUserMail.toLowerCase() === userMail.toLowerCase()) {
            if (selectedSource.length > 0 && question) {
                // userInputQuestionFromServer(question)
            }
        }
    }

    const defaultCenterContent = () => {
        return (
            <div className="text-center my-4">
                <img src={companySmallLogo} height="75px" className="shadow-lg rounded-circle" />
                <h1 className="h3 text-gray-900">Welcome to <img className="img-fluid mb-1 bl-h-2" src={kairosLogo} alt="app-logo" /></h1>
                <button className="btn btn-outline-primary btn-sm">What can I do?</button>
            </div>
        )
    }

    const aiFooterContent = () => {
        return (
            <div id="aiFooter" className="bl-search-footer">
                {/* {
                    selectedSource.length ? (
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex mb-2 p-2 bl-selected-source text-nowrap">
                                    <div className={`d-flex overflow-hidden ${remainingSelectedSource && remainingSelectedSource.length && 'bl-selected-source-sec'}`}>
                                        Selected Sources (<b>{selectedSource.length}</b>):
                                        {
                                            top5SelectedSource.map((s, i) => {
                                                return (
                                                    <span key={`selected-source-${i}`} onMouseOver={() => showSourceBasedSuggestiveQuestion(s)} title={convertCamelCaseToSeparateWords(s.title)} className="badge bl-filter-chip bl-bg-lightpink bl-limited-text d-block px-2 ml-2 cp">{convertCamelCaseToSeparateWords(s.title)}</span>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        remainingSelectedSource && remainingSelectedSource.length ? (
                                            <div className="dropup">
                                                <span className="badge bl-filter-chip bl-bg-lightpink bl-w-1rem ml-2 px-3" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                </span>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        remainingSelectedSource.map((s, i) => {
                                                            return <a key={`source-dropdown-${i}`} onMouseOver={() => showSourceBasedSuggestiveQuestion(s)} className="dropdown-item cp">{convertCamelCaseToSeparateWords(s.title)}</a>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    ) : null
                } */}
                <form className="shadow-lg" onSubmit={(e) => getQuestionInputByUser(e, userQuestion)}>
                    <div className={`input-group border rounded ${selectedSource.length === 0 || loggedUserMail.toLowerCase() !== userMail.toLowerCase() ? 'disable' : ''}`}>
                        {/* <div className={`input-group-prepend`}>
                            <span className="input-group-text bg-white border-0 p-2">
                                <img className="img-fluid bl-h-1-25rem" src={kairosIcon} />
                            </span>
                        </div> */}
                        <input type="text" onChange={(e) => { setUserQuestion(e.target.value) }} value={userQuestion} className="form-control h-auto border-0 shadow-none" placeholder={`${selectedSource.length !== 0 ? "Chat not enabled for you" : "Start typing... Ask me something about your data"}`} />
                        <div className="input-group-append p-1 align-items-center">
                            <button className="btn btn-link">
                                1 Source
                            </button>
                            <button type="submit" className="btn btn-primary btn-circle btn-sm bl-border-radius-round">
                                <i className="fa fa-play" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const suggestiveQuestionContent = () => {
        return (
            <ul className="list-group list-group-flush my-4">
                <li className="list-group-item border-0 px-0 d-flex align-items-center"><i className="fa fa-comments-o" aria-hidden="true"></i><h6 className="mb-0 ml-2">Get going by typing your question or request; for inspiration, browse through these examples!</h6></li>
                <li className="list-group-item list-group-item-action px-0 d-flex justify-content-between align-items-center cp"><span className="bl-truncate-line-1">Show me top 5 companies with highest total assets in Canada.</span><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                <li className="list-group-item list-group-item-action px-0 d-flex justify-content-between align-items-center cp"><span className="bl-truncate-line-1">Compare the account receivable values for accounts under The Boeing Company and ...</span> <i className="fa fa-angle-right" aria-hidden="true"></i></li>
                <li className="list-group-item list-group-item-action px-0 d-flex justify-content-between align-items-center cp"><span className="bl-truncate-line-1">Which companies have the highest total assets in each country?</span> <i className="fa fa-angle-right" aria-hidden="true"></i></li>
                <li className="list-group-item list-group-item-action px-0 d-flex justify-content-between align-items-center cp"><span className="bl-truncate-line-1">What are the trends in spend amounts for IT over the years?</span> <i className="fa fa-angle-right" aria-hidden="true"></i></li>
            </ul>
        )
    }

    const toggleSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section.style.width === "100px") {
            section.style.width = section.dataset.width;
            section.style.visibility = "visible";
        } else {
            section.style.width = "100px";
            //   section.style.visibility = "hidden";
        }
    }

    const arrangeColumnValue = (item, column) => {

        if (column !== 'USD (MILLIONS)') {
            return formatCurrency(item[column]);

        }
        else return item.Text ? item.Text : item.text;
    }

    const getIncomeStatementData = () => {
        const data = [
            {
                "date": "FY 2022-23",
                "revenue": 211.915
            },
            {
                "date": "FY 2021-22",
                "revenue": 198.27
            },
            {
                "date": "FY 2020-21",
                "revenue": 168.088
            },
            {
                "date": "FY 2019-20",
                "revenue": 143.015
            },
            {
                "date": "FY 2018-19",
                "revenue": 125.843
            }
        ];
        setIncomePeriodData(data);
        setCategoryField('date');
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'date');
        setIncomeSeries(items)

        const tableData = [
            {
                "Text": "Revenue",
                "FY 2022-23": 211.915,
                "FY 2021-22": 198.27
            },
            {
                "Text": "Product",
                "FY 2022-23": 64.699,
                "FY 2021-22": 72.732
            },
            {
                "Text": "Service and other",
                "FY 2022-23": 147.216,
                "FY 2021-22": 125.538
            },
            {
                "Text": "Cost of revenue",
                "FY 2022-23": 65.863,
                "FY 2021-22": 62.65
            },
            {
                "Text": "Product",
                "FY 2022-23": 17.804,
                "FY 2021-22": 19.064
            },
            {
                "Text": "Service and other",
                "FY 2022-23": 48.059,
                "FY 2021-22": 43.586
            },
            {
                "Text": "Gross margin",
                "FY 2022-23": 146.052,
                "FY 2021-22": 135.62
            },
            {
                "Text": "Research and development",
                "FY 2022-23": 27.195,
                "FY 2021-22": 24.512
            },
            {
                "Text": "Sales and marketing",
                "FY 2022-23": 22.759,
                "FY 2021-22": 21.825
            },
            {
                "Text": "General and administrative",
                "FY 2022-23": 7.575,
                "FY 2021-22": 5.9
            },
            {
                "Text": "Impairment, integration, and restructuring",
                "FY 2022-23": "-",
                "FY 2021-22": "-"
            },
            {
                "Text": "Operating income",
                "FY 2022-23": 88.523,
                "FY 2021-22": 83.383
            },
            {
                "Text": "Other income, net",
                "FY 2022-23": 0.788,
                "FY 2021-22": 0.333
            },
            {
                "Text": "Profit/(Loss) before tax",
                "FY 2022-23": 89.311,
                "FY 2021-22": 83.716
            },
            {
                "Text": "Provision for income taxes",
                "FY 2022-23": 16.95,
                "FY 2021-22": 10.978
            },
            {
                "Text": "Profit/(Loss) for the year",
                "FY 2022-23": 72.361,
                "FY 2021-22": 72.738
            }
        ]
        let tableHeader = [];
        if (tableData.length) {
            for (let key in tableData[0]) {
                const headerText = (key).toLowerCase() === 'text' ? 'USD (Millions)' : key;
                tableHeader.push({
                    header: headerText.toUpperCase(),
                    field: key
                })
            }
            setIncomeTableHeader(tableHeader)
            setIncomeTableInfo(tableData)
        }
    }

    const graphTableInfoContent = (columns, data) => {
        data = data.slice(0, 10)
        return (
            <DataTable key={`items`} value={data} className="bl-finance-graph">
                {
                    columns.map((col, i) => {
                        const uniqueVal = Date.now() + Math.random();
                        return (
                            <Column key={`graph-table-List-view-${i}-${uniqueVal.toString()}`} header={col.header} field={col.field} body={(e) => arrangeColumnValue(e, col.header)}></Column>
                        )
                    })
                }
            </DataTable>
        )
    }

    const leftPanelDetails = () => {
        return (
            <section>
                <div className="card-body">
                    <ColumnChart id="incomeStatementFinance" data={incomePeriodData} categoryField={categoryField} seriesItem={incomeSeries} height="20rem" />
                    {
                        graphTableInfoContent(incomeTableHeader, incomeTableInfo)
                    }
                </div>
            </section>
        )
    }

    const rightPanelDetails = () => {
        return (
            <section>
                <section className="mb-3 pb-3 px-3 border-bottom">
                    <div className="d-flex justify-content-between align-items-center cp" data-toggle="collapse" data-target="#collapseExplanation" aria-expanded="false" aria-controls="collapseExplanation">
                        <div className="d-flex align-items-center">
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                            <h6 className="mb-0 ml-2">Explanation</h6>
                        </div>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </div>
                    <div className="collapse pt-3" id="collapseExplanation">
                        <div className="card card-body">
                            <div>
                                <h6 className="text-muted">Citations and References (5) </h6>
                                <ul>
                                    <li>Portfolio company spend analysis</li>
                                    <li>Annual Revenue By Us State</li>
                                    <li>Assets By Country</li>
                                </ul>
                            </div>
                            <div>
                                <h6 className="text-muted">Cypher / SQLQuery (if applicable)</h6>
                                <p><i>SELECT company_name FROM company_data WHERE company_id = 1;</i></p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mb-3 pb-3 px-3 border-bottom">
                    <div className="d-flex justify-content-between align-items-center cp" data-toggle="collapse" data-target="#collapseDataSource" aria-expanded="false" aria-controls="collapseDataSource">
                        <div className="d-flex align-items-center">
                            <i className="fa fa-database" aria-hidden="true"></i>
                            <h6 className="mb-0 ml-2">Knowledge Model Data Sources (10)</h6>
                        </div>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </div>
                    <div className="collapse pt-3" id="collapseDataSource">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" defaultChecked />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Chronograph</label>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" defaultChecked />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Financial</label>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Board Decks</label>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Vendor Spends</label>
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <button className="btn btn-circle btn-primary btn-sm"><i className="fa fa-cog" aria-hidden="true"></i></button>
                                <div>
                                    <i className="fa fa-upload" aria-hidden="true"></i> Upload File
                                </div>
                            </li>
                        </ul>
                    </div>

                </section>
                <section className="mb-3 pb-3 px-3 border-bottom">
                    <div className="d-flex justify-content-between align-items-center cp" data-toggle="collapse" data-target="#collapseAIAgent" aria-expanded="false" aria-controls="collapseAIAgent">
                        <div className="d-flex align-items-center">
                            <i className="fa fa-diamond" aria-hidden="true"></i>
                            <h6 className="mb-0 ml-2">AI Agent Settings</h6>
                        </div>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </div>
                    <div className="collapse pt-3" id="collapseAIAgent">
                        <div className="card card-body">
                            <textarea rows={2} className="form-control border-0 p-0" placeholder="Enter Optional Instructions here..." />
                            <div className="d-flex justify-content-between align-items-center pt-3">
                                <button className="btn btn-circle btn-primary btn-sm"><i className="fa fa-cog" aria-hidden="true"></i></button>
                                <div>
                                    <i className="fa fa-save" aria-hidden="true"></i> Save Now
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="mb-3 pb-3 px-3">
                    <div className="d-flex justify-content-between align-items-center cp" data-toggle="collapse" data-target="#collapsePreference" aria-expanded="false" aria-controls="collapsePreference">
                        <div className="d-flex align-items-center">
                        <img width={'20px'} src="https://cdn-icons-png.flaticon.com/512/152/152529.png" alt="apps icon"/>
                            <h6 className="mb-0 ml-2">Preferences</h6>
                        </div>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </div>
                    <div className="collapse pt-3" id="collapsePreference">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h6 className="mb-0">Allow Follow-up Questions</h6>
                            <label className="bl-switch mb-0">
                                <input type="checkbox" className="form-control" defaultChecked />
                                <span className="bl-slider round"></span>
                            </label>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h6 className="mb-0">Use External Data</h6>
                            <label className="bl-switch mb-0">
                                <input type="checkbox" className="form-control" defaultChecked />
                                <span className="bl-slider round"></span>
                            </label>
                        </div>
                    </div>

                </section>
            </section>
        )
    }

    const centerPanelContent = () => {
        return (
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col-11 m-auto">
                            {defaultCenterContent()}
                            {suggestiveQuestionContent()}
                        </div>
                    </div>
                    {aiFooterContent()}
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            <div className="col kairos-modal d-flex vh-100">
                <div className="kairos-modal-left-panel border border-danger px-3" id="kairos-left-panel" data-width="450px">
                    <div className="card kairos-modal-panel-height">
                        <div className="card-header py-2 d-flex justify-content-between">
                            <h6 className="mb-0">Selected Widget / Context</h6>
                            <i className="fa fa-columns cp" aria-hidden="true" onClick={() => toggleSection('kairos-left-panel')}></i>
                        </div>
                        <div className="card-body overflow-auto">
                            {leftPanelDetails()}
                        </div>
                    </div>
                </div>
                <div className="kairos-modal-content border border-danger px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card kairos-modal-panel-height">
                                    <div className="card-header p-0">
                                        <nav className="navbar navbar-expand bl-kairos-chat-sec p-0">
                                            <ul className="navbar-nav m-auto align-items-center">
                                                <li className="nav-item active">
                                                    <a className="nav-link">Start a Chat</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link">Edit Selected Widget with Canvas</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="card-body d-flex flex-column-reverse overflow-auto">
                                        {centerPanelContent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kairos-modal-right-panel border border-danger px-3" id="kairos-right-panel" data-width="350px">
                    <div className="card kairos-modal-panel-height">
                        <div className="card-header py-2 d-flex justify-content-between">
                            <h6 className="mb-0">Detailed Information</h6>
                            <i className="fa fa-columns cp" aria-hidden="true" onClick={() => toggleSection('kairos-right-panel')}></i>
                        </div>
                        <div className="card-body overflow-auto px-0">
                            {rightPanelDetails()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}