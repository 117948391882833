import React, { useContext, useEffect, useState } from "react";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation } from "react-router-dom";
import { SpeakerBriefContext } from "../../services/context/speakerBriefContext";
import ThemeContext from "../../services/context/Theme/themeContext";
import { getAppBehavior } from "../../services/authService";
import { appendThemeProperty } from "../../services/commonService";


export const SpeakerBriefConfigurationComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { SBModels } = useContext(SpeakerBriefContext)
    const { theme } = useContext(ThemeContext)
    const location = useLocation();

    const applyTheme = () => {
        const appThemes = getAppBehavior();
        if (theme) {
            appendThemeProperty(theme, true, true)
        }
        else if (appThemes && appThemes !== "undefined") {
            const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
            if (appTheme) {
                appendThemeProperty(appTheme, true, true)
            }
        }
    }

    useEffect(() => {
        applyTheme()
        setBreadcrumbMenu([
            { slug: '/speaker-brief', menuName: 'Speaker Brief', isActive: true, hasSubMenu: true },
            { slug: `/speaker-brief/configuartion`, menuName: "Configuration", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])
    const [selectedSBModel, setSelectedSBModel] = useState({
        displayName: "", modelId: null, speakerProfileUI:[]
    })

    const [cardInfo, setCardInfo] = useState()

    useEffect(() => {
        if (SBModels && SBModels.length) {
            setSelectedSBModel(SBModels[0])
        }
        applyTheme()
    }, [SBModels])

    useEffect(() => {
        if (selectedSBModel && selectedSBModel.speakerProfileUI) {
            setCardInfo(selectedSBModel.speakerProfileUI)
        }
    }, [selectedSBModel])

    const changeModelSelection = (modelId) => {
        const model = SBModels.find(model => model.modelId === modelId);
        if (model && model.modelId) {
            setSelectedSBModel(model)
        }
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col">
                        <section>
                            <div className="text-center my-4">
                                <img src="https://media.licdn.com/dms/image/D4E03AQHuiOzNEXxr2w/profile-displayphoto-shrink_200_200/0/1648234547710?e=2147483647&v=beta&t=tN5AUtkxi08L87N2TRkF8kFYvM6r-9G01Q_i-z4UEs4" height="80px" className="rounded-circle" />
                                <h4 className="h4 text-gray-800 text-center">Speaker Profile of Robert F Smith</h4>
                                <div className="dropdown show">
                                    <a className="btn btn-sm btn-outline-primary dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {selectedSBModel.displayName} <i className="fa fa-caret-down ml-2" aria-hidden="true"></i>
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        {
                                            SBModels && SBModels.map((model, i) => {
                                                return (
                                                    <a key={`sb-models-config-${i}`} className="dropdown-item" onClick={(e) => changeModelSelection(model.modelId)}>{model.displayName}</a>
                                                )
                                            })
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="row px-3">
                    <div className="col-12">
                        {
                            cardInfo && cardInfo.map((info, index) => {
                                return (
                                    <div key={`sb-config-card-${index}`} className=" border-bottom py-4">
                                        <h4 className="font-weight-normal text-gray-800" id="">{info.title}</h4>
                                        <ul className="pl-3">
                                            {
                                                info.list && info.list.map((item, j) => {
                                                    return (
                                                        <li key={`sb-config-item-${index}${j}`} className="p-1"><strong>{item.title}:</strong>&nbsp;{item.desc}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="col-3 d-none">
                        <div className="card border-0 mb-3">
                            <div className="card-body p-3 bg-gray-100 rounded">
                                <h5 className="card-title">Quick Highlights</h5>
                                <ul className="pl-3">
                                    <li>Recognized as one of the most influential voices in private equity.</li>
                                    <li>Known for engaging Fortune 500 executives, investors, and policymakers.</li>
                                    <li>Delivered keynote speeches at major global conferences (e.g., Davos, TechSummit)</li>
                                </ul>
                            </div>
                        </div>

                        <div className="card border-0 mb-3">
                            <div className="card-body p-3 bg-gray-100 rounded">
                                <h5 className="card-title">Featured Quotes</h5>
                                <ul className="pl-3">
                                    <li><i>"We must ensure technology empowers, not excludes, communities across the globe."</i></li>
                                    <li><i>"Innovation and inclusion are not mutually exclusive—they are mutually reinforcing."</i></li>
                                </ul>
                            </div>
                        </div>

                        <div className="card border-0 mb-3">
                            <div className="card-body p-3 bg-gray-100 rounded">
                                <h5 className="card-title">Past Events</h5>
                                <ul className="pl-3">
                                    <li>Keynote Speaker – World Economic Forum, Davos 2023.</li>
                                    <li>Panelist – TechLeadership Summit 2022.</li>
                                    <li>Keynote – Diversity in Tech Conference 2021.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}