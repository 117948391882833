import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import blDefaultLogo from './../assets/img/PE_logo.svg';
import gifImage from './../assets/img/AI.gif';
import { authenticateByCreadentialService, authenticateKairosAppForIframeService, getApplicationInfo, getToken, storeAppBehaviour, storeOrgView, storeToken, storeUserInfo } from "../services/authService";
import { AuthContext } from "../services/context/Auth/authContext";
import { toastError } from "../services/notifyService";
import { appendThemeProperty, getAppConfigService, getOrganizationThemeService, themeIcons } from "../services/commonService";
import ThemeContext from "../services/context/Theme/themeContext";
import componentOverView from "./../utils/property-file/componentOverview.json";


const LoginComponent = () => {

    window.scrollTo(0, 0)
    const { setLoggedUserName, setBLRoles } = useContext(AuthContext)
    const { theme, setTheme, setAppLogo } = useContext(ThemeContext)
    const nav = useNavigate();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [ssoAuth, setSSOAuth] = useState(null)
    const [welcomeText, setWelcomeText] = useState('')
    const [companyLogo, setCompanyLogo] = useState('')
    const [kairosFullLogo, setKairosFullLogo] = useState('')
    const [themeName, setThemeName] = useState('')
    const [themes, setThemes] = useState([])
    const [isDefaultBLTheme, setIsDefaultBLTheme] = useState(false)
    const [orgView, setOrgView] = useState("")
    const [subDomain, setSubDomain] = useState("")
    const [trustedApp] = useState('https://kind-river-0da007c0f.3.azurestaticapps.net')

    useEffect(() => {
        const _subDomain = getSubDomain();
        setSubDomain(_subDomain)
        window.addEventListener("message", handleMessage);
        try {
            window.parent.postMessage("iframe-ready", trustedApp);
        } catch (error) {

        }

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [])

    const getSubDomain = () => {
        const { hostname } = window.location;
        const parts = hostname.split('.');
        if (parts.length >= 2) {
            return parts[0];
        }
        return null;
    }

    const handleMessage = (event) => {
        try {
            if (event.origin !== trustedApp) {
                // console.warn("Iframe app: Untrusted origin:", event.origin);
                return;
            }
            const token = event.data?.token;
            if (token) {
                // getAppInfo()
                authenticateKairosAppForIframe()
            } else {
                // console.warn("Iframe app: Token is undefined");
            }
        }
        catch (e) {
            // console.log("Error while fetching iframe info ===>", e);
        }

    };

    const authenticateKairosAppForIframe = async () => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex'
        }
        const resp = await authenticateKairosAppForIframeService();
        if (pageLoader) {
            pageLoader.style.display = 'none'
        }
        if (resp && resp.success) {
            let kairosTokenInfo = resp.data;
            kairosTokenInfo['iframeUser'] = true;
            storeLoggedUserInfo(kairosTokenInfo)
        }
    }

    const storeLoggedUserInfo = (info) => {
        const isNewUser = info?.isNewUser || false;
        let obj = {
            username: info.displayName,
            email: info.email,
            userGroups: info.userGroups,
            userSchema: info.userSchema,
            userType: info.userType,
            BLRoles: info.BLRoles ? info.BLRoles : [],
            isNewUser: isNewUser,
            dataSource: info?.dataSource || [],
            iframeUser: info?.iframeUser
        }
        storeUserInfo(JSON.stringify(obj));
        setBLRoles(obj.BLRoles)
        storeToken(info.sessionToken);
        window.location.href = "/#/kairos";
    }

    useEffect(() => {
        // getAppInfo();
        getOrganizationTheme();
        setUserName('')
        setPassword('')
        sessionValidation()
        document.getElementsByTagName('body')[0].classList.add('bl-login-bg');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('bl-login-bg')
        }
    }, [])

    useEffect(() => {
        if (theme) {
            appendThemeProperty(theme)
        }
    }, [theme])

    useEffect(() => {

        let defaultTheme = themes.find(theme => theme.subDomain && subDomain ? theme.subDomain === subDomain : theme.isDefault);
        if (!defaultTheme) {
            defaultTheme = themes.find(theme => theme.isDefault);
        }
        if (defaultTheme) {
            setIsDefaultBLTheme(defaultTheme.themeName === "BL_THEME")
            setOrgView(defaultTheme.themeName)
        }
    }, [themes, subDomain])

    useEffect(() => {
        const orgTheme = themes.find(org => org.themeName === orgView);
        if (orgTheme) {
            storeOrgView(orgTheme.themeId)
            setOrganizationTheme(orgTheme)
        }
    }, [orgView])

    const getOrganizationTheme = async () => {
        const resp = await getOrganizationThemeService();
        if (resp && resp.status) {
            setThemes(resp.response)
        }
    }

    const getAppInfo = async () => {
        const resp = await getAppConfigService();
        if (resp && resp.status) {
            // setOrganizationTheme(resp.response)
        }
    }

    const setOrganizationTheme = (orgTheme) => {
        setThemeName(orgTheme.themeName)
        setAppLogo(orgTheme.logo)
        setTheme(JSON.parse(orgTheme.behaviour))
        storeAppBehaviour(JSON.stringify(orgTheme))
        const compLogo = themeIcons(`${orgTheme.themeName}_Full_Logo`);
        const kairos_Full_Logo = themeIcons(`${orgTheme.themeName}_K_Logo`);
        const componentOverViewElement = componentOverView.find(ele => ele.FEATURE === 'LOGIN');
        const _welcomeText = componentOverViewElement.WELCOME_TEXT.find(text => text.theme === orgTheme.themeName);
        setWelcomeText(_welcomeText.text)
        setCompanyLogo(compLogo)
        setKairosFullLogo(kairos_Full_Logo)
    }

    const sessionValidation = () => {
        if (getToken()) {
            navigateToNextPage()
        }
        else {
            getClientId()
        }
    }

    const navigateToNextPage = () => {
        setTimeout(() => {
            nav('/home')
        }, 200)
    }

    const getClientId = async () => {
        const res = await getApplicationInfo();
        if (res && res.success) {
            const clientId = res.data.clientId;
            const appName = res.data.applicationName ? res.data.applicationName : 'brownloop.com';
            const redirect = `${window.location.origin}/`;
            const url = `https://login.microsoftonline.com/${appName}/oauth2/authorize?scope=openid&client_id=${clientId}&response_type=code&redirect_uri=${redirect}&response_mode=query`;
            setSSOAuth(url)
        }
    }

    const loginUser = async (e) => {
        e.preventDefault();
        let credential = {
            userName: userName,
            password: password
        }
        setIsLoading(true)
        const resp = await authenticateByCreadentialService(credential);
        setIsLoading(false)
        if (resp && resp.success) {
            setLoggedUserName(resp.data.userName)
            const obj = {
                username: resp.data.userName
            }
            storeUserInfo(JSON.stringify(obj))
            storeToken(resp.data.sessionToken);
            // nav('/dashboard')
            nav('/home')
        }
        else {
            toastError('Invalid Credential!')
        }
    }

    return (
        <>
            <header className="d-flex flex-wrap py-3">
                <a className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none pl-5 pt-2">
                    <img id="applogo" src={companyLogo} height={themeName === 'BL_THEME' ? "16px" : "30px"} />
                </a>
            </header>

            <div className="container BLC_vis_container">
                <div className="row justify-content-center">

                    <div className="col-5">

                        <div className="card o-hidden border-0 my-4 BLC_vis_login_card shadow-lg rounded">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-12 d-lg-block text-center">
                                        <img className="bl-login-img-height-20" src={gifImage} />
                                    </div>
                                    <div className="col-12 align-content-center">
                                        <div className="p-5">
                                            <div>
                                                <h1 className="h3 text-gray-900 text-center">Login to <img className="img-fluid mb-1 bl-h-2" src={kairosFullLogo} alt="app-logo" /></h1>
                                                <p className="text-muted text-center" dangerouslySetInnerHTML={{ __html: welcomeText }}></p>
                                            </div>
                                            {
                                                isDefaultBLTheme ? (
                                                    <div>
                                                        <br />
                                                        <label>Choose your organization view</label>
                                                        <select className="form-control form-control-sm" value={orgView} onChange={(e) => setOrgView(e.target.value)}>
                                                            {
                                                                themes.map((app, i) => {
                                                                    return (
                                                                        <option key={`organization-theme-${i}`} value={app.themeName}>{app.displayName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <br />
                                                    </div>
                                                ) : null
                                            }
                                            {/* <br /><br /><br /><br />
                                            <form onSubmit={(e) => loginUser(e)} className="user">
                                                <div className="form-group">
                                                    <input type="text" onChange={(e) => setUserName(e.target.value)} value={userName} className="form-control form-control-user BLC_vis_login_textBox" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address..." />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className="form-control form-control-user BLC_vis_login_textBox" id="exampleInputPassword" placeholder="Password" />
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox small">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                        <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-danger text-white btn-user btn-block shadow bl-text-medium BLC_vis_login_btn" disabled={isLoading}>
                                                    Login
                                                </button>
                                            </form> */}
                                            <br />
                                            <form className="user">
                                                <a href={ssoAuth} className="btn btn-danger text-white btn-block shadow">
                                                    <i className="fa fa-windows fa-fw"></i> Sign in
                                                </a>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <footer className="footer bg-transparent d-flex justify-content-between text-smaller">
                <span className="pl-5">English (United States)</span>
                <span className="pr-5">
                    <div className="d-flex bd-highlight">
                        {/* <div className="pl-5 bd-highlight">Help</div>
                        <div className="pl-5 bd-highlight">Privacy</div>
                        <div className="pl-5 bd-highlight">Terms</div>
                        <div className="pl-5 bd-highlight">Copyright © 2024 Brownloop Inc. All rights reserved.</div> */}
                        <div className="pl-5 bd-highlight">Powered by <img src={blDefaultLogo} height="35px" /></div>
                    </div>
                </span>
            </footer>
        </>
    )
}

export default LoginComponent;