import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../services/context/Auth/authContext";
import AIContext from "../../services/context/AIAssistance/aiContext";
import { CardDropdownContent } from "./cardDropdown";
import { useNavigate } from "react-router-dom";
import { getNewsFeedService, getRelativeTime } from "../../services/commonService";


export const RightPanelComponent = () => {

  const { userGroups, applicableApps, isCanabisApp } = useContext(AuthContext)
  const { setAIModalType } = useContext(AIContext);
  const nav = useNavigate();

  const [usedApps, setUsedApps] = useState([])
  const [myFeeds, setMyFeeds] = useState([])
  const [feedType, setFeedType] = useState('news')
  const [feedOptions, setFeedOptions] = useState([{name:"Google News", value:"news"}])

  useEffect(() => {
    const defaultApps = [
      {
        name: "Analytics",
        icon: "fa-pie-chart",
        path: "/dashboard"
      },
      {
        name: "AI App",
        icon: "fa-gg-circle",
        path: "/analytics/overview"
      }
    ]
    // setUsedApps(defaultApps)

    const financeFeed = [
      {
        "source": "Korea Economic Daily",
        "title": "LG injects $97 mn into Vista Equity’s fund for new tech investments",
        "date": "20 May 2024",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMiR2h0dHBzOi8vd3d3LmtlZGdsb2JhbC5jb20vY29ycG9yYXRlLWludmVzdG1lbnQvbmV3c1ZpZXcva2VkMjAyNDAzMjAwMDE20gExaHR0cHM6Ly9hbXAua2VkZ2xvYmFsLmNvbS9uZXdzQW1wL2tlZDIwMjQwMzIwMDAxNg?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "Reuters",
        "title": "Vista Equity Partners to buy EngageSmart for $4 billion",
        "date": "Oct 2",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMibmh0dHBzOi8vd3d3LnJldXRlcnMuY29tL21hcmtldHMvZGVhbHMvdmlzdGEtZXF1aXR5LXBhcnRuZXJzLW5lYXJzLTQtYmlsbGlvbi1idXlvdXQtZW5nYWdlc21hcnQtd3NqLTIwMjMtMTAtMjMv0gEA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "Business Wire",
        "title": "Vista Equity Partners Announces 2023 Senior Leadership Promotions",
        "date": "Dec 21",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMiemh0dHBzOi8vd3d3LmJ1c2luZXNzd2lyZS5jb20vbmV3cy9ob21lLzIwMjMxMjIxNjgxNjU5L2VuL1Zpc3RhLUVxdWl0eS1QYXJ0bmVycy1Bbm5vdW5jZXMtMjAyMy1TZW5pb3ItTGVhZGVyc2hpcC1Qcm9tb3Rpb25z0gEA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "Blackstone",
        "title": "Energy Exemplar to be Acquired by Blackstone and Vista Equity Partners",
        "date": "Oct 31",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMibWh0dHBzOi8vd3d3LmJsYWNrc3RvbmUuY29tL25ld3MvcHJlc3MvZW5lcmd5LWV4ZW1wbGFyLXRvLWJlLWFjcXVpcmVkLWJ5LWJsYWNrc3RvbmUtYW5kLXZpc3RhLWVxdWl0eS1wYXJ0bmVycy_SAQA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "Cision",
        "title": "TRG Screen Announces Strategic Growth Investment from Vista Equity Partners",
        "date": "Oct 3",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMigwFodHRwczovL3d3dy5wcm5ld3N3aXJlLmNvbS9uZXdzLXJlbGVhc2VzL3RyZy1zY3JlZW4tYW5ub3VuY2VzLXN0cmF0ZWdpYy1ncm93dGgtaW52ZXN0bWVudC1mcm9tLXZpc3RhLWVxdWl0eS1wYXJ0bmVycy0zMDE5NDQ2MTEuaHRtbNIBAA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "Fortune",
        "title": "From KKR to Thoma Bravo: How 10 top private equity firms are performing as sky-high tech prices from 2021 fall back to earth",
        "date": "Feb 29",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMiXGh0dHBzOi8vZm9ydHVuZS5jb20vMjAyNC8wMi8yOC90aG9tYS1icmF2by10ZWNoLWRlYWxzLTIwMjEtYnViYmxlLXZpc3RhLWVxdWl0eS1yb2JlcnQtc21pdGgv0gFgaHR0cHM6Ly9mb3J0dW5lLmNvbS8yMDI0LzAyLzI4L3Rob21hLWJyYXZvLXRlY2gtZGVhbHMtMjAyMS1idWJibGUtdmlzdGEtZXF1aXR5LXJvYmVydC1zbWl0aC9hbXAv?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "Power Technology",
        "title": "Blackstone and Vista Equity to acquire Australia's Energy Exemplar",
        "date": "Nov 1",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMiVmh0dHBzOi8vd3d3LnBvd2VyLXRlY2hub2xvZ3kuY29tL25ld3MvYmxhY2tzdG9uZS12aXN0YS1lcXVpdHktYWNxdWlyZS1lbmVyZ3ktZXhlbXBsYXIv0gEA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "Wall Street Journal",
        "title": "Vista Equity Partners Agrees to $4 Billion Buyout of EngageSmart - WSJ ",
        "date": "Oct 23",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMibWh0dHBzOi8vd3d3Lndzai5jb20vZmluYW5jZS9pbnZlc3RpbmcvdmlzdGEtZXF1aXR5LXBhcnRuZXJzLWNsb3NlLXRvLTQtYmlsbGlvbi1idXlvdXQtb2YtZW5nYWdlc21hcnQtYTMyODMxZGPSAQA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "Financier Worldwide ",
        "title": "Vista Equity Partners to acquire EngageSmart for $4bn — Financier Worldwide ",
        "date": "Dec 12",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMiV2h0dHBzOi8vd3d3LmZpbmFuY2llcndvcmxkd2lkZS5jb20vdmlzdGEtZXF1aXR5LXBhcnRuZXJzLXRvLWFjcXVpcmUtZW5nYWdlc21hcnQtZm9yLTRibtIBAA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      }
    ]

    const vctFeed = [
      {
        "source": "Business Wire",
        "title": "Vista Equity Partners Announces 2023 Senior Leadership Promotions",
        "date": "Dec 21",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMiemh0dHBzOi8vd3d3LmJ1c2luZXNzd2lyZS5jb20vbmV3cy9ob21lLzIwMjMxMjIxNjgxNjU5L2VuL1Zpc3RhLUVxdWl0eS1QYXJ0bmVycy1Bbm5vdW5jZXMtMjAyMy1TZW5pb3ItTGVhZGVyc2hpcC1Qcm9tb3Rpb25z0gEA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "Pensions & Investments",
        "title": "Tapping into enterprise software expertise in private credit",
        "date": "Dec 11",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMiL2h0dHBzOi8vd3d3LnBpb25saW5lLmNvbS92aXN0YS1wcml2YXRlLWNyZWRpdDIz0gEA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "The",
        "title": "Private Equity Firm Empowering Enterprise Software Founders",
        "date": "Nov 9",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMibGh0dHBzOi8vd3d3LmluYy5jb20vdmlzdGFlcXVpdHlwYXJ0bmVycy90aGUtcHJpdmF0ZS1lcXVpdHktZmlybS1lbXBvd2VyaW5nLWVudGVycHJpc2Utc29mdHdhcmUtZm91bmRlcnMuaHRtbNIBAA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "GrowthCap",
        "title": "The Top Growth Equity Firms of 2023",
        "date": "Feb 28",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMiQmh0dHBzOi8vZ3Jvd3RoY2FwYWR2aXNvcnkuY29tL3RoZS10b3AtZ3Jvd3RoLWVxdWl0eS1maXJtcy1vZi0yMDIzL9IBAA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "Xactly",
        "title": "Xactly Welcomes Jennifer McAdams as Chief Marketing Officer",
        "date": "Oct 26",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMihgFodHRwczovL3d3dy5nbG9iZW5ld3N3aXJlLmNvbS9lbi9uZXdzLXJlbGVhc2UvMjAyMy8xMC8yNi8yNzY3MjM3LzAvZW4vWGFjdGx5LVdlbGNvbWVzLUplbm5pZmVyLU1jQWRhbXMtYXMtQ2hpZWYtTWFya2V0aW5nLU9mZmljZXIuaHRtbNIBAA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "PE Hub",
        "title": "Exit interview: Vista discusses $4.6bn sale of Apptio to IBM",
        "date": "Jul 5, 2023",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMiRWh0dHBzOi8vd3d3LnBlaHViLmNvbS92aXN0YS1lcXVpdHktcGFydG5lcnMtc3llZC1kZXRhaWxzLWFwcHRpby1leGl0L9IBAA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "Forbes",
        "title": "The Triumph Of Revenue Engineering Over Financial Engineering",
        "date": "Oct 31",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMicGh0dHBzOi8vd3d3LmZvcmJlcy5jb20vc2l0ZXMvc3RlcGhlbmRpb3Jpby8yMDIzLzEwLzMxL3RyaXVtcGgtb2YtcmV2ZW51ZS1lbmdpbmVlcmluZy1vdmVyLWZpbmFuY2lhbC1lbmdpbmVlcmluZy_SAQA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "GrowthCap",
        "title": "The Top Private Equity Firms of 2023",
        "date": "Sep 18, 2023",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMiQ2h0dHBzOi8vZ3Jvd3RoY2FwYWR2aXNvcnkuY29tL3RoZS10b3AtcHJpdmF0ZS1lcXVpdHktZmlybXMtb2YtMjAyMy_SAQA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      },
      {
        "source": "Wall Street Journal",
        "title": "Vista Equity's Rachel Arnold Stays Busy With Small Technology Deals",
        "date": "Jun 14, 2023",
        icon: "fa-google-plus-official",
        "url": "https://news.google.com/articles/CBMiaGh0dHBzOi8vd3d3Lndzai5jb20vYXJ0aWNsZXMvdmlzdGEtZXF1aXR5cy1yYWNoZWwtYXJub2xkLXN0YXlzLWJ1c3ktd2l0aC1zbWFsbC10ZWNobm9sb2d5LWRlYWxzLTNlMzljODA20gEA?hl=en-CA&gl=CA&ceid=CA%3Aen"
      }
    ]

    let feedList = [];
    if (userGroups.find(grp => grp === "bl.cloud.int.group1") !== -1) {
      feedList = financeFeed
    }
    else {
      feedList = vctFeed
    }

    
    setMyFeeds(feedList)
  }, [])

  useEffect(() => {
    const apps = applicableApps.filter(app => app.appName !== 'DASHBOARD');
    setUsedApps(apps)
  }, [applicableApps])

  useEffect(() => {
    if(isCanabisApp){
      const list = [
        {name:"All", value:"all"},
        {name:"Reddit", value:"reddit"},
        {name:"Google News", value:"news"}
      ]
      setFeedOptions(list)
      getNewsFeed()
    }
  }, [feedType, isCanabisApp])

  const getNewsFeed = async() => {
    const obj = {
      pageSize: 14,
      page: 1,
      source: feedType
    }
    const resp = await getNewsFeedService(obj);
    console.log("Respo in news feed ===>", resp);
    if(resp && resp.articles){
      const items = resp.articles.map(item => {
        return {
          source: "",
          title: item.title ? item.title : item.link_title,
          date: item.date ? getRelativeTime(item.date) : "Today",
          icon: item.type === "news-article" ? "fa-google-plus-official" : "fa-reddit" ,
          url: item.url ? item.url : item.link_permalink
      }
      })
      if(items && items.length){
        setMyFeeds(items)
      }
    }
  }

  const recentlyUsedAppGrid = () => {
    return (
      <div className="row">
        {
          usedApps.map((app, i) => {
            return (
              app.isModal ? (
                <div key={`app-list-${i}`} className="col-2 text-center cp font-smaller" onClick={() => setAIModalType('DEFAULT')} data-toggle="modal" data-target="#aimodalbox">
                  {
                    app.image ? <img src={app.image} alt="menu-icon" className="mb-1" width={'25px'} /> : <i className={`fa fa-2x mb-1 ${app.icon}`} aria-hidden="true"></i>
                  }
                  <p className="small mb-0 text-nowrap">{app.displayName}</p>
                </div>
              ) : (
                <div key={`app-list-${i}`} className="col-2 text-center cp font-smaller" onClick={() => nav(app.path)}>
                  {
                    app.image ? <img src={app.image} alt="menu-icon" className="mb-1" width={'25px'} /> : <i className={`fa fa-2x mb-1 ${app.icon}`} aria-hidden="true"></i>
                  }
                  <p className="small mb-0 text-nowrap">{app.displayName}</p>
                </div>
              )
            )
          })
        }
      </div>
    )
  }

  const selectFeed = (val) => {
    setFeedType(val);
  }

  return (
    <div className="row">
      <section className="col-12 bg-dark h-13rem overflow-y-auto recent-app">
        <div className="text-white">
          <div className="pt-3 text-white">
            <div className="col-12">
              <p className="font-weight-600">Recently Used Apps</p>
            </div>
            {
              recentlyUsedAppGrid()
            }

          </div>
        </div>
      </section>
      <section className="bg-gray p-3">
        <div className="row p-2">
          <div className="d-flex col-6">
            <h5 className="h5 mb-0 text-black font-weight-600">My Feed</h5>
            <div className="ml-2">
              <CardDropdownContent cardId="myfeeddropdown" cardType={'REDDIT'} onChildCardEvent={(item) => setAIModalType(item)} />
            </div>
          </div>
          <div className="col-6 text-right">
            <div className="btn-group">
              <select id="contentview" value={feedType} onChange={(e) => selectFeed(e.target.value)} className="form-control form-control-sm blc_button text-black">
                {
                  feedOptions.map((option, i) => {
                    return (
                      <option key={`feed-options-${i}`} value={option.value}>{option.name}</option>
                    )
                  })
                }                
              </select>
            </div>
          </div>

        </div>
        <div className="feed">
          {
            myFeeds.map((feed, i) => {
              return (
                <div key={`my-feed-item-${i}`} className="post-item clearfix">
                  <span><i className={`fa ${feed.icon}`} aria-hidden="true"></i></span>
                  <p>{
                    feed.url ? (<a href={feed.url} target="_blank">{feed.title}</a>) : feed.title
                  }</p>
                  <p className="small">{feed.date} {feed.source ? `| ${feed.source}` : ''}</p>
                </div>
              )
            })
          }
        </div>
      </section>
    </div>
  )
}