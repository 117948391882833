import React, { useContext, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbComponent from "./breadcrumbs";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { AuthContext } from "../../services/context/Auth/authContext";
import { getUserInfo, logoutService, removeToken } from "../../services/authService";
import { getActiveUserListForAdminService } from "../../services/aiService";
import { getRoleBasedModelsService } from "../../services/modelService";
import aIModelIcon from "./../../assets/icons/ai-model-icon.png"
import AIContext from "../../services/context/AIAssistance/aiContext";


export const HeaderComponent = () => {

    const { setGlobalSearch, profileBGColor } = useContext(SearchContext)
    const { breadcrumbMenu } = useContext(BreadcrumbContext)
    const { loggedUserName, userGroups, userSchema, userMail, loggedUserMail, setUserMail } = useContext(AuthContext)
    const { rolesModelId, setRolesModelId, setRolesModelName } = useContext(AIContext)
    const location = useLocation();
    const nav = useNavigate()

    const [showBar, setShowBar] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [userList, setUserList] = useState([]);
    const [models, setModels] = useState([]);
    const debounceDelay = 1000;
    const [isOpen, setIsOpen] = useState(false);
    const profileDropdownRef = useRef(null);
    const [isRolesModelVisible, setIsRolesModelVisible] = useState(false)

    useEffect(() => {
        setModels([])
        getModels()
        const handleClickOutside = (event) => {
            if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [])

    useEffect(() => {
        const path = location.pathname.split('/');
        const pathname = path && path.length ? path[1] : '';
        const apps = ['kairos', 'admin']
        setIsRolesModelVisible(apps.includes(pathname))
        const componentHeader = document.getElementById('componentHeader');
        const mainHeader = document.getElementById('vistaHeader');
        if (componentHeader) {
            if (mainHeader) {
                mainHeader.classList.add('bl-z-index-1031');
                mainHeader.classList.remove('shadow');
            }
        }
        else {
            if (mainHeader) {
                mainHeader.classList.add('shadow');
                mainHeader.classList.remove('bl-z-index-1031');
            }
        }
    }, [location.pathname])

    useEffect(() => {
        if (rolesModelId) {
            const modelInfo = models.find(m => m.modelId === rolesModelId);
            setRolesModelName(modelInfo && modelInfo.modelName ? modelInfo.modelName : "")
        }
        const path = location.pathname.split('/');
        const pathname = path && path.length ? path[1] : '';
        const apps = ['admin'];
        if (apps.includes(pathname)) {
            if (path[2] === 'data-entity-configuration') {
                nav(`/admin/data-entity-configuration`)
            }
            else if (path[2] === 'testing-harness') {
                nav(`/admin/testing-harness`)
            }
        }
    }, [rolesModelId])

    useEffect(() => {
        window.$(function () {
            window.$('[data-toggle="tooltip"]').tooltip()
        })
        if (breadcrumbMenu.length && breadcrumbMenu[0].hasSubMenu) {
            setShowBar(breadcrumbMenu[0].hasSubMenu)
        }
        else {
            setShowBar(false)
        }
    }, [breadcrumbMenu])

    useEffect(() => {
        const timer = setTimeout(() => {
            setGlobalSearch(searchTerm)
        }, debounceDelay);

        return () => {
            clearTimeout(timer);
        };
    }, [searchTerm]);

    useEffect(() => {
        setUserList([])
        setTimeout(() => {
            getActiveUserListForAdmin(1)
        })
    }, [])

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
    };

    const handleKeySearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setGlobalSearch(searchTerm)
        }
    }

    const getUserNameProfile = () => {
        const userInfo = JSON.parse(getUserInfo());
        const username = userInfo && userInfo.username ? userInfo.username : '';
        if (username) {
            const splitName = username.split(' ');
            if (splitName.length > 1) {
                return `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`
            }
            else return `${splitName[0].charAt(0)}${splitName[0].charAt(1)}`
        }
        else {
            return ''
        }
    }

    const getActiveUserListForAdmin = async (page) => {
        const limit = 10;
        const obj = {
            page: page,
            pageSize: limit
        }
        const resp = await getActiveUserListForAdminService(obj);
        if (resp && resp.success) {
            setUserList(list => [...list, ...resp.data])
            if (resp.data.length >= limit) {
                getActiveUserListForAdmin(page + 1);
            }
        }
    }

    const getModels = async () => {
        const resp = await getRoleBasedModelsService();
        if (resp && resp.success) {
            setModels(resp.data)
            if (resp.data.length) {
                if (!rolesModelId) {
                    setRolesModelId(resp.data[0].modelId)
                    setRolesModelName(resp.data[0].modelName)
                }
            }
        }
    }

    const logout = async () => {
        await logoutService();
        removeToken();
        window.location.href = "/#/login";
    }

    const logoutContent = () => {
        return (
            <div className="modal fade d-print-none" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-primary secondary-link" type="button" data-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary primary-link" data-dismiss="modal" onClick={() => logout()}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const preventClick = (e) => {
        e.preventDefault()
    }

    return (
        <>
            <nav className="navbar navbar-expand navbar-light topbar fixed-top mb-0 shadow vistaHeaderMargin vistaCollapsedHeaderMargin d-print-none" id="vistaHeader">

                {/* <!-- Sidebar Toggle (Topbar) --> */}
                {/* <button id="sidebarToggleTop" className="btn btn-link rounded-circle mr-0">
                    <i className="fa fa-window-maximize"></i>
                </button> */}



                {/* <!-- Topbar Navbar --> */}
                <ul className="navbar-nav ml-auto d-flex justify-content-between align-items-center w-100">
                    <li className="nav-item">
                        <div className="d-flex align-items-center">
                            {
                                showBar ? <button id="togglersubmenu" className="btn btn-link rounded-circle mr-0 text-muted" data-toggle="tooltip" data-placement="bottom" title="Hide / Open Nav Panel">
                                    <i className="fa fa-bars"></i>
                                </button> : null
                            }
                            {/* <div><h6 className="font-weight-bold text-muted m-0">{componentName}</h6></div> */}
                            <div className={showBar ? '' : 'ml-3'}>
                                <BreadcrumbComponent item={breadcrumbMenu}></BreadcrumbComponent>
                            </div>
                        </div>
                    </li>
                    {/* <!-- Nav Item - Search Dropdown (Visible Only XS/Mobile) --> */}
                    <li className="nav-item dropdown no-arrow d-sm-none">
                        <button className="nav-link dropdown-toggle" id="searchDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-search fa-fw"></i>
                        </button>
                        {/* <!-- Dropdown - Messages --> */}
                        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                            <form className="form-inline mr-auto w-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fa fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>

                    <li className="nav-item d-flex align-items-center">
                        {/* <img src={aIModelIcon} alt="model icon" className="img-fluid" /> */}
                        {
                            isRolesModelVisible ? (
                                <div className="d-flex align-items-center">
                                    <h6 className="mb-0 text-nowrap mr-2">Active Model</h6>
                                    <select className="form-control shadow-sm border border-primary px-4" value={rolesModelId} onChange={(e) => setRolesModelId(e.target.value)}>
                                        {
                                            models && models.length ? models.map((model, i) => {
                                                return (
                                                    <option key={`model-info-${i}`} value={model.modelId}>{model.displayName}</option>
                                                )
                                            }) : <option></option>
                                        }
                                    </select>
                                </div>
                            ) : null
                        }
                    </li>

                    <div className="topbar-divider d-none"></div>

                    {/* <!-- Nav Item - User Information panel --> */}
                    <li className="nav-item dropdown no-arrow">
                        <button className="nav-link dropdown-toggle" id="userDropdown" onClick={() => setIsOpen(true)}>
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small text-capitalize">{loggedUserMail.toLowerCase() === userMail.toLowerCase() ? loggedUserName : userMail}</span>
                            <span className="bl-profile-circle text-uppercase">{getUserNameProfile()}</span>
                            {/* <span className="bl-profile-circle text-uppercase border-0 text-white" style={{background: profileBGColor}}>{getUserNameProfile()}</span> */}
                        </button>
                        {
                            isOpen && (
                                <div ref={profileDropdownRef} id="profileDropdown" className="bl-profile-dropdown" aria-labelledby="userDropdown">
                                    <h6 className="dropdown-header bg-secondary border-0 text-white">
                                        Profile Information
                                    </h6>
                                    <a className="dropdown-item d-flex align-items-center border-bottom py-3">
                                        <div className="dropdown-list-image mr-3">
                                            <div className="bl-profile-circle text-uppercase text-center">{getUserNameProfile()}</div>
                                            {/* <div className="bl-profile-circle text-uppercase text-center border-0 text-white" style={{background: profileBGColor}}>{getUserNameProfile()}</div> */}
                                            <div className="status-indicator bg-success"></div>
                                        </div>
                                        <div>
                                            <h6 className="mb-1"><b>{loggedUserName}</b></h6>
                                            <div className="text-truncate mb-1">{loggedUserMail}</div>
                                            {
                                                userGroups.map((group, i) => {
                                                    return (
                                                        <div key={`user-group-${i}`} className="text-gray-500">
                                                            <span className="badge badge-pill badge-light border"><i className="fa fa-users fa-sm fa-fw text-gray-400"></i> {group}</span>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>

                                    </a>

                                    {
                                        userList && userList.length ?
                                            <a className="dropdown-item p-0 bl-user-list">
                                                <ul className="p-0">
                                                    {
                                                        userList.map((user, i) => {
                                                            return (
                                                                <li key={`user-list-${i}`} className={`dropdown-item small border-bottom py-2 ${userMail.toLowerCase() === user.userId.toLowerCase() ? 'active' : ''}`} onClick={() => { setUserMail(user.userId); setIsOpen(false) }}>
                                                                    <i className="fa fa-user fa-sm mr-2 text-gray-400"></i>
                                                                    {user.userId}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>

                                            </a> : null
                                    }
                                    <a className="dropdown-item text-center py-2" data-toggle="modal" data-target="#logoutModal">Sign Out</a>
                                </div>
                            )
                        }

                    </li>

                </ul>

            </nav>
            {logoutContent()}
        </>
    )
}