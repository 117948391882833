import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPinnedReportListService, getReportCategoryListService } from "../../services/reportService";
import pdfMenu from "./../../data/PDF/subMenu.json";
import adminMenu from "./../../data/Admin/subMenu.json";
import { SummarizerContext } from "../../services/context/summarizerContext";
import { AnalyzeCompanyContext } from "../../services/context/analyzeCompanyContext";
import { generateCIMId } from "../../services/summarizerService";


export const SubMenuComponent = () => {

    const location = useLocation();
    const { projectInfo, projectSections, projectMode } = useContext(SummarizerContext)
    const { setAnalyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const nav = useNavigate();
    const [activeSubTab, setActiveSubTab] = useState('');
    const [activeSupSubMenu, setActiveSupSubMenu] = useState('');
    const [pinnedReport, setPinnedReport] = useState([]);
    const [analyticsSubMenuList, setAnalyticsSubMenuList] = useState([]);
    const [pdfSubMenuList, setPdfSubMenuList] = useState([]);
    const [mainMenuItem, setMainMenuItem] = useState('')
    const [dealBookProjectSlug, setDealBookProjectSlug] = useState('')

    useEffect(() => {
        getAnalyticsCategorySubMenu();
        getPinnedReportList()
    }, [])

    useEffect(() => {
        if (projectInfo && projectInfo.projectName) {
            setDealBookProjectSlug(generateCIMId(projectInfo.projectId))
        }
    }, [projectInfo, location.pathname])

    useEffect(() => {
        const path = mainPageContent();
        setMainMenuItem(path)
        setActiveSubTab(getSubMenu())
    }, [location])

    useEffect(() => {
        setPdfSubMenuList(pdfMenu)
    }, [pdfMenu])

    const getSubMenu = () => {
        setActiveSupSubMenu('')
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 2) {
            if (pathArray[2] === 'reports' && pathArray.length === 4) {
                return pathArray[3]
            }
            else if (pathArray[2] === 'reports' && pathArray.length === 5) {
                setActiveSupSubMenu(`${pathArray[3]}-${pathArray[4]}`)
                return pathArray[3]
            }
            else if (pathArray[2] === 'reports' && pathArray[3] === 'category' && pathArray.length === 6) {
                setActiveSupSubMenu(pathArray[5])
                return pathArray[4]
            }
            else if (pathArray[2] === 'reports' && pathArray[3] !== 'category' && pathArray.length === 6) {
                setActiveSupSubMenu(pathArray[4])
                return pathArray[3]
            }
            else if (pathArray[2] === 'project' && pathArray.length >= 4) {
                return pathArray[3]
            }
            else if (pathArray[1] === 'speaker-brief' && pathArray[2] === 'events') {
                if (pathArray.length === 4) {
                    return pathArray[3]
                }
                else if (pathArray.length > 4) {
                    return pathArray[4]
                }
            }
            return pathArray[2]
        }
    }

    const mainPageContent = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 1) {
            return pathArray[1];
        }

    }

    const getAnalyticsCategorySubMenu = async () => {
        const resp = await getReportCategoryListService();
        if (resp && resp.success) {
            const menuItem = [
                {
                    menuType: "CATEGORIES",
                    list: resp.data
                }
            ]
            setAnalyticsSubMenuList(menuItem)
        }
    }

    const getPinnedReportList = async () => {
        const obj = {
            page: 1, pageSize: 4
        }
        const resp = await getPinnedReportListService(obj);
        if (resp && resp.success) {
            setPinnedReport(resp.data)
        }
    }

    const getSubMenuContent = () => {
        const mainMenu = mainPageContent();
        if (mainMenu === 'analytics') {
            return analyticsSubMenu();
        }
        else if (mainMenu === 'pdf') {
            return pdfSubMenu()
        }
        else if (mainMenu === 'admin') {
            return adminSubMenu();
        }
        else if (mainMenu === 'dealbook') {
            return dealBookSubMenu()
        }
        else if (mainMenu === 'speaker-brief') {
            return speakerBriefSubMenu()
        }
        else {
            return commonSubMenu();
        }
    }

    const getNavigationLink = (item, sectionType, mainMenu) => {
        if (sectionType === 'CATEGORIES') {
            if (mainMenu) {
                return `/analytics/reports/category/${mainMenu.slug}/${item.slug}`
            }
            return `/analytics/reports/${item.slug}`
        }
        else {
            return `/analytics/${item.slug}`
        }
    }

    const changeAccordionBGColor = (parentId, childId) => {
        const childElement = document.getElementById(childId);
        if (childElement.classList.contains('show')) {
            const parentElement = document.getElementById(parentId);
            parentElement.classList.remove('bl-bg-black')
        }
        else {
            const parentElement = document.getElementById(parentId);
            parentElement.classList.add('bl-bg-black')
        }
    }

    const accordionMenusContent = (menu, list, indx, type, sectionType) => {
        return (
            <div key={`${type}-list-${indx}`} id={`${type}parent${indx}`} className="accordion bl-menu-accordion nav-link m-0 bl-bg-black">
                <div className="accordion-item">
                    <h6 className={`accordion-header cp ${activeSubTab === menu.slug ? 'text-white' : ''}`} id={`${type}submenuheading${indx}`} onClick={() => changeAccordionBGColor(`${type}parent${indx}`, `${type}submenucollapseitem-${indx}`)} data-toggle="collapse" data-target={`#${type}submenucollapseitem-${indx}`} aria-expanded="false" aria-controls={`${type}submenucollapseitem-${indx}`}>
                        <span><i className={`fa fa-fw ${menu.icon}`}></i> {menu.name}</span>
                        <span className="bl-accordion-icon border-0 bl-pr-point3">
                            <i className="fa fa-angle-down accordion-button m-0" onClick={() => changeAccordionBGColor(`${type}parent${indx}`, `${type}submenucollapseitem-${indx}`)} data-toggle="collapse" data-target={`#${type}submenucollapseitem-${indx}`} aria-expanded="false" aria-controls={`${type}submenucollapseitem-${indx}`}></i>
                        </span>
                    </h6>
                    <div id={`${type}submenucollapseitem-${indx}`} className="accordion-collapse show collapse m-0" aria-labelledby={`${type}submenuheading${indx}`}>
                        <div className="accordion-body pb-2">
                            <ul className="navbar-nav">
                                {
                                    list.map((subItem, i) => {
                                        return (
                                            <li key={`${type}${subItem.slug}-${i}-${indx}`} className={activeSupSubMenu === subItem.slug ? 'nav-item mb-0 selected' : 'nav-item mb-0'}>
                                                <button key={`${type}-sub-menu-item-${indx}-${i}`} className={`nav-link d-flex justify-content-between align-items-center m-0 bl-pr-point3 ${subItem.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(getNavigationLink(subItem, sectionType, menu)); setActiveSubTab(subItem.slug) }}>
                                                    <span className="ml-3">{subItem.name}</span>
                                                    {
                                                        // sectionType === 'CATEGORIES' ? <span className="bl-report-count">{subItem.count ? `${subItem.count}` : '0'}</span> : null
                                                    }
                                                </button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getAnalyticsSubMenu = (list, sectionType) => {
        return (
            <>
                {
                    list.map((menu, i) => {
                        return (
                            <li key={`analytics-sub-${menu.name}-${sectionType}`} className={activeSubTab === menu.slug ? 'nav-item selected' : 'nav-item'}>
                                {
                                    menu.list && menu.list.length ? accordionMenusContent(menu, menu.list, i, 'analytics', sectionType)
                                        : (
                                            <button className={`nav-link d-flex justify-content-between align-items-center ${menu.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(getNavigationLink(menu, sectionType)); setActiveSubTab(menu.slug) }}>
                                                <span><i className={`fa fa-fw ${menu.icon}`}></i> {menu.name}</span>
                                                {
                                                    // sectionType === 'CATEGORIES' ? <span className="bl-report-count">{menu.count ? `${menu.count}` : '0'}</span> : null
                                                }
                                            </button>
                                        )
                                }

                            </li >
                        )
                    })
                }
            </>
        )
    }

    const analyticsSubMenu = () => {

        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                <li className={activeSubTab === 'overview' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/analytics/overview'); setActiveSubTab('overview') }}>
                        <span><i className="fa fa-fw fa-tachometer"></i> Overview</span></button>
                </li>
                {
                    pinnedReport.length > 0 ? (<div className="sidebar-heading pb-2 mt-4">
                        PINNED REPORTS
                    </div>) : null
                }
                {
                    pinnedReport.map((p, i) => {
                        const reportSlug = p.categoryInfo && p.categoryInfo.length ? `${p.categoryInfo[0].categorySlug}-${p.reportNameSlug}` : ''
                        const reportPath = p.categoryInfo && p.categoryInfo.length ? `${p.categoryInfo[0].categorySlug}/${p.reportNameSlug}` : ''
                        return (
                            <li key={`pinned-report-${i + 1}`} className={activeSupSubMenu === reportSlug ? 'nav-item selected' : 'nav-item'}>
                                <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/analytics/reports/${reportPath}`); setActiveSupSubMenu(reportSlug) }}>
                                    <span><i className="fa fa-fw fa-thumb-tack"></i> {p.reportName}</span></button>
                            </li>
                        )
                    })
                }
                {
                    analyticsSubMenuList.map((items, i) => {
                        if (items.list.length) {
                            return (
                                <React.Fragment key={`analytics-sub-menu-${items.menuType}-${i + 1}`}>
                                    <div key={`analytics-sub-header-${i + 1}`} className="sidebar-heading pb-2 mt-4">
                                        {items.menuType}
                                    </div>
                                    {
                                        getAnalyticsSubMenu(items.list, items.menuType)
                                    }
                                </React.Fragment>
                            )
                        }

                    })
                }


            </ul>
        )
    }

    const pdfSubMenu = () => {

        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <div className="sidebar-heading pb-2 mt-4">
                    COMPANY LIST
                </div>
                {
                    pdfMenu.map((pdf, i) => {
                        return (
                            <li key={`pdf-view-${i}`} className={activeSubTab === pdf.slug ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                                <button className={`nav-link d-flex justify-content-between align-items-center ${pdf.isDisabled ? 'disabled' : 'text-white'}`} onClick={() => { nav(`/pdf/${pdf.slug}`); setActiveSubTab(pdf.slug) }}>
                                    <span><i className={`fa fa-fw ${pdf.icon}`}></i> {pdf.name}</span></button>
                            </li>
                        )
                    })
                }

            </ul>
        )
    }

    const adminSubMenu = () => {

        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <div className="sidebar-heading pb-2 mt-4">
                    ADMIN CONSOLE
                </div>
                {
                    adminMenu.map((pdf, i) => {
                        return (
                            <li key={`admin-view-${i}`} className={activeSubTab === pdf.slug ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                                <button className={`nav-link d-flex justify-content-between align-items-center ${pdf.isDisabled ? 'disabled' : 'text-white'}`} onClick={() => { nav(`/admin/${pdf.slug}`); setActiveSubTab(pdf.slug) }}>
                                    <span><i className={`fa fa-fw ${pdf.icon}`}></i> {pdf.name}</span></button>
                            </li>
                        )
                    })
                }

            </ul>
        )
    }

    const dealBookSubMenu = () => {        
        let companyProjectActiveSec = '';
        if (dealBookProjectSlug === activeSubTab) {
            const pathArray = location.pathname.split('/');
            companyProjectActiveSec = pathArray[pathArray.length - 1];
        }
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 2 && pathArray[2] === 'project') {
            return (
                <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                    <li className={'nav-item mt-3'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/dealbook/home'); setActiveSubTab('home'); setAnalyzedCompanyInfo({message: "Dashboard Page"}) }}>
                            <span><i className="fa fa-fw fa-arrow-left"></i> Main Menu</span></button>
                    </li>
                    <div className="sidebar-heading pb-2 mt-4">
                        SECTIONS
                    </div>
                    {
                        projectSections && projectSections.map((sec, i) => {
                            if(sec.sectionIdentifier && sec.sectionIdentifier === "scoreCard"){
                                return null
                            }
                            return (
                                <li key={`dealBook-proj-sections-${i}`} className={activeSubTab === sec.slug ? 'nav-item selected' : 'nav-item'}>
                                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/dealbook/project/${sec.slug}/${projectMode}`) }}>
                                        <span><i className={`fa fa-fw fa-${sec.icon}`}></i> {sec.categoryName}</span></button>
                                </li>
                            )
                        })
                    }
                    <div className="sidebar-heading pb-2 mt-4">
                        MORE
                    </div>
                    <li className={activeSubTab === dealBookProjectSlug && companyProjectActiveSec === 'files' ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/dealbook/project/${dealBookProjectSlug}/files`) }}>
                            <span><i className="fa fa-fw fa-file-pdf-o"></i> View File</span></button>
                    </li>
                </ul>
            )
        }
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <button className="btn btn-danger m-2 mt-3 align-self-center text-nowrap bl-w-fill-available btn-sm" data-toggle="modal" data-target="#analyzeNewModal">
                    <span><i className="fa fa-fw fa-plus-circle"></i> Create New Summary</span>
                </button>
                <li className={activeSubTab === 'home' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/dealbook/home`); setActiveSubTab('home') }}>
                        <span><i className={`fa fa-fw fa-home`}></i> Home</span></button>
                </li>
                <div className="sidebar-heading pb-2 mt-4">
                    CONFIGURATION
                </div>
                <li className={activeSubTab === 'templates' ? 'nav-item selected' : 'nav-item'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/dealbook/templates`) }}>
                        <span><i className={`fa fa-fw fa-window-maximize`}></i> Configure Template</span></button>
                </li>
            </ul>
        )
    }

    const speakerBriefSections = (id) => {
        return [
            {
                displayName: "Speaker Brief",
                path: `${id}/info`,
                slug: "info",
                icon: "binoculars"
            },
            {
                displayName: "Event Details",
                path: `${id}/event-details`,
                slug: "event-details",
                icon: "info-circle"
            }
        ]
    }

    const speakerBriefSubMenu = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 2 && pathArray[2] === 'events') {
            const id = pathArray.length === 5 ? pathArray[3] : null;
            const createEvents = pathArray[pathArray.length - 1] === 'manage';
            const eventSections = createEvents ? [
                {
                    displayName: "Create Event",
                    path: "manage",
                    slug: "manage",
                    icon: "plus-circle"
                }
            ] : speakerBriefSections(id);
            return (
                <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                    <li className={'nav-item mt-3'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/speaker-brief/dashboard'); setActiveSubTab('dashboard') }}>
                            <span><i className="fa fa-fw fa-arrow-left"></i> Main Menu</span></button>
                    </li>
                    {
                        eventSections && eventSections.length ? (
                            <div className="sidebar-heading pb-2 mt-4">
                                SECTIONS
                            </div>
                        ) : null
                    }
                    {
                        eventSections && eventSections.map((sec, i) => {
                            return (
                                <li key={`dealbook-proj-sections-${i}`} className={activeSubTab === sec.slug ? 'nav-item selected' : 'nav-item'}>
                                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/speaker-brief/events/${sec.path}`) }}>
                                        <span><i className={`fa fa-fw fa-${sec.icon}`}></i> {sec.displayName}</span></button>
                                </li>
                            )
                        })
                    }
                </ul>
            )
        }
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <button className="btn btn-danger m-2 mt-3 align-self-center text-nowrap bl-w-fill-available btn-sm" onClick={() => { nav(`/speaker-brief/events/manage`) }}>
                    <span><i className="fa fa-fw fa-plus-circle"></i> Create New Event</span>
                </button>
                <li className={activeSubTab === 'dashboard' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/speaker-brief/dashboard`); setActiveSubTab('dashboard') }}>
                        <span><i className={`fa fa-fw fa-calendar`}></i> Events</span></button>
                </li>
                <li className={activeSubTab === 'configuration' ? 'nav-item selected' : 'nav-item'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/speaker-brief/configuration`) }}>
                        <span><i className={`fa fa-fw fa-cog`}></i> Configuration</span></button>
                </li>
            </ul>
        )
    }

    const commonSubMenu = () => {
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <div className="sidebar-heading py-2">
                    General Settings
                </div>
            </ul>
        )
    }    

    return (
        <div className="sub-menu-content d-print-none">
            {getSubMenuContent()}
        </div>
    )
}