import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

export const DisplayHtmlContent = ({ htmlContent, contentId = 'sechtmlinfo', title }) => {

    const [content, setContent] = useState('');
    const [sanitizedHtml, setSanitizedHtml] = useState(null)

    function containsChart(doc) {
        const hasCanvasChart = doc.querySelectorAll('canvas').length > 0;

        const hasSVGChart = doc.querySelectorAll('svg').length > 0;

        const hasChartClass = doc.querySelector('.chartjs-render-monitor') !== null ||
            doc.querySelector('.highcharts-container') !== null ||
            doc.querySelector('.google-chart') !== null;

        return hasCanvasChart || hasSVGChart || hasChartClass;
    }

    useEffect(() => {
        if (htmlContent) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlContent, 'text/html');
            const isContainsChart = containsChart(doc);
            setTimeout(() => {
                const bodyElement = document.getElementById(contentId);


                if (isContainsChart) {
                    const blob = new Blob([htmlContent], { type: 'text/html' });
                    const fileUrl = window.URL.createObjectURL(blob);
                    setContent(`<iframe class="bl-innerhtml-iframe" id=iframeElement${contentId} src="${fileUrl}" width="100%"></iframe>`);
                }
                else {
                    let tempDiv = document.createElement('div');
                    tempDiv.innerHTML = htmlContent;

                    const linkTags = tempDiv.querySelectorAll('link');
                    linkTags.forEach(link => link.remove());

                    let updatedHtmlContent = tempDiv.innerHTML;
                    setContent(updatedHtmlContent)
                }

                const parentElement = document.getElementById(contentId);
                if (parentElement) {
                    let tableElement = parentElement.querySelector('table');
                    if (tableElement) {
                        tableElement.classList.add('table', 'table-sm', 'border');
                    }
                }
                if (bodyElement && isContainsChart) {
                    setTimeout(() => {
                        adjustIframeHeight(`iframeElement${contentId}`)
                    }, 800)
                }

            }, 100)
        }

    }, [htmlContent, contentId])

    function adjustIframeHeight(iframeId) {
        const iframe = document.getElementById(iframeId);
        if (iframe) {
            iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 20 + 'px';
        }
    }

    useEffect(() => {
        if (content) {
            const sanitizedHtmlContent = DOMPurify.sanitize(content);
            setSanitizedHtml(sanitizedHtmlContent)
        }
    }, [content])

    // console.log("Check html content ====>", html);
    return <div className='bl-inner-html-content' id={contentId} dangerouslySetInnerHTML={{ __html: content }} />;
};
