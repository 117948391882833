import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import { generateReadableId, validateAndFormatDate } from "../../../utils/validation";
import homeLabel from "./../../../utils/property-file/componentOverview.json";
import listViewLoader from "./../../../assets/img/loading-animation.gif";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation, useNavigate } from "react-router-dom";
import { getTemplateListService } from "../../../services/summarizerService";


export const DealBookTemplatesHomeComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const nav = useNavigate()
    const location = useLocation()

    const [templates, setTemplates] = useState([])
    const [templateKPI, setTemplateKPI] = useState([])
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: [],
        RECOMMEND: ""
    });
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getTemplateList()
        setBreadcrumbMenu([
            { slug: '/dealbook', menuName: 'DealBook', isActive: true, hasSubMenu: true },
            { slug: `/dealbook/templates`, menuName: "Templates", isActive: false, hasSubMenu: true },
            { slug: `/dealbook/templates`, menuName: "Home", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "DEAL_BOOK_TEMPLATE").items;
        setLabels(items)
    }, [homeLabel])

    const getTemplateList = async() => {
        setIsLoading(true)
        try{
            const resp = await getTemplateListService();
            if (resp && resp.success) {
                const templateList = resp.data;
                setTemplates(templateList)
            }
        }
        catch(e){

        }
        finally{
            setIsLoading(false)
        }
    }

    const getTemplateInfo = (info) => {
        const id = generateReadableId(info.data.templateId, 'TP')
        nav(`/dealbook/templates/${id}`)
    }

    const configureNew = (e, id) => {
        e.preventDefault();
        nav(`/dealbook/templates/configure`, {state: {templateId: id}})
    }

    const tableValues = (info, field) => {
        const template = info;
        if (template) {
            if (field === "ID") {
                return generateReadableId(template.templateId, 'TP')
            }
            else if (field === "CREATED_ON") {
                return validateAndFormatDate(template?.createdOn || new Date())
            }
            else if (field === "SECTION") {
                const categories = template.categories && template.categories.length ? template.categories.map(item => item.name).join(', ') : '';
                return (
                    <div className="bl-truncate-line-2">{categories}</div>
                )
            }
            else if(field === "ACTION"){
                return <button className="btn btn-circle btn-primary btn-sm" title="Make a Copy" onClick={(e) => configureNew(e, template.templateId)}><i className="fa fa-clone" aria-hidden="true"></i></button>
            }
        }
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-12">
                        <section>
                            <div className="media p-2">
                                <div className="media-body">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0">{labels.WELCOME_DESC}</p>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src="https://img.freepik.com/free-vector/manager-prioritizing-tasks-list_74855-5272.jpg" alt="overview-Img" />
                            </div>
                        </section>

                        <section className="mb-3">
                            <div className="card-deck">
                                {
                                    templateKPI && templateKPI.map((kpi, i) => {
                                        return (
                                            <div key={`template-kpi-${i}`} className="card shadow-sm">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="text-muted"><h6>{kpi.title}</h6></div>
                                                        {
                                                            kpi.icon ? <div className="icon-shape icon-md bg-light-primary text-primary rounded-2 bl-db-kpi-fontsize">
                                                                <i className={`fa fa-${kpi.icon} ${kpi.colorClass}`} aria-hidden="true"></i>
                                                            </div> : null
                                                        }
                                                    </div>
                                                    <h5 className={`mb-0`}><b>{kpi.description}</b></h5>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-12 px-0">
                                            <div className="border-bottom pl-2">
                                                <h6 className="mb-3">{labels.RECOMMEND}</h6>
                                            </div>
                                            {
                                                isLoading ? <div className="col text-center">
                                                    <img className="bl-list-loader" src={listViewLoader} />
                                                </div> : (
                                                    <DataTable value={templates} rowClassName={"bl-source-overview-list position-relative"} className="cp table table-sm" selectionMode="single" onRowClick={getTemplateInfo}>
                                                        <Column field="templateId" header="#" body={(e) => tableValues(e, 'ID')} sortable></Column>
                                                        <Column field="templateName" header="Template Name" sortable></Column>
                                                        <Column field="templateDescription" header="Description" sortable></Column>
                                                        <Column field="createdBy" header="Created By" sortable></Column>
                                                        <Column field="createdOn" header="Created On" dataType="date" body={(e) => tableValues(e, 'CREATED_ON')} sortable></Column>
                                                        <Column field="" header="Sections" body={(e) => tableValues(e, 'SECTION')}></Column>
                                                        {/* <Column field="" header="" body={(e) => tableValues(e, 'ACTION')}></Column> */}
                                                    </DataTable>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}