import React, { useContext, useEffect, useState } from "react";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import homeLabel from "./../../../utils/property-file/componentOverview.json";
import overviewIcon from "./../../../assets/img/overviewIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllTestPlanRunHistoryService, getAllTestPlanService, runTestPlanService, testJobStatusService } from "../../../services/testService";
import moment from "moment";
import { convertCamelCaseToSeparateWords } from "../../../services/commonService";
import { toastInfo, toastSuccess } from "../../../services/notifyService";
import { getAIModelService } from "../../../services/aiService";
import listViewLoader from "./../../../assets/img/loading-animation.gif";
import reportForm from "../../../utils/property-file/reports/resourceForm.json";
import buttonsLabel from "../../../utils/property-file/buttons-label.json";
import AIContext from "../../../services/context/AIAssistance/aiContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from "primereact/progressbar";
import { AuthContext } from "../../../services/context/Auth/authContext";

export const TestingHarnessHomeComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { rolesModelId } = useContext(AIContext)
    const { userMail } = useContext(AuthContext)
    const nav = useNavigate()
    const location = useLocation();

    const [viewType, setViewType] = useState('PLANS')
    const [planRunningId, setPlanRunningId] = useState(null)

    useEffect(() => {
        setIsTestRunning(false)
        setRunningPlan({
            testPlanMasterId: null, testPlanName: ''
        })
        getAIModels()
        setBreadcrumbMenu([
            { slug: '', menuName: 'Admin', isActive: true, hasSubMenu: true },
            { slug: `/admin/testing-harness`, menuName: "Testing Harness", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])

    useEffect(() => {
        setTestPlans([])
        setTestPlansRuns([])
        if (rolesModelId) {
            setTimeout(() => {
                getAllTestPlan(1)
                getAllTestPlanRunHistory()
            }, 50)
        }
    }, [location.pathname, rolesModelId])

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: [],
        RECOMMEND: ""
    });
    const [testPlansRuns, setTestPlansRuns] = useState([])
    const [testPlans, setTestPlans] = useState([])
    const [testPlanKPI, setTestPlanKPI] = useState([])
    const [defaultModel, setDefaultModel] = useState({
        aiModelId: null, displayName: '', model: ''
    })
    const [planExecutionInfo, setPlanExecutionInfo] = useState(location.state || {});
    const [isLoading, setIsLoading] = useState(false)
    const [isTestRunning, setIsTestRunning] = useState(false)
    const [runningPlan, setRunningPlan] = useState({
        testPlanMasterId: null, testPlanName: ''
    })

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "TESTING_HARNESS").items;
        setLabels(items)
    }, [homeLabel])

    const getAllTestPlanRunHistory = async () => {
        setIsLoading(true)
        const obj = {
            modelId: rolesModelId
        }
        const resp = await getAllTestPlanRunHistoryService(obj);
        if (resp && resp.success) {
            const KPIMetrics = resp.data.metrics;
            const kpiMetricsArray = Object.entries(KPIMetrics).map(([key, value]) => {
                return { title: convertCamelCaseToSeparateWords(key), description: value };
            });
            setTestPlanKPI(kpiMetricsArray)
            let plans = resp.data && resp.data.testRuns ? resp.data.testRuns : [];
            if (planExecutionInfo.jobExecuted && planExecutionInfo.testPlanId) {
                plans = plans.map(job => job.testPlanMasterId == planExecutionInfo.testPlanId ? {
                    ...job, runStatus: "pending", percentage: 0,
                    completeCount: 0, runDate: new Date()
                } : job)
                setPlanExecutionInfo({})
            }
            plans.sort((a, b) => new Date(b.runDate) - new Date(a.runDate));
            setTestPlansRuns(plans)
        }
        setIsLoading(false)
    }

    const getAllTestPlan = async (page) => {
        setIsLoading(true)
        const limit = 50;
        const obj = {
            page: page, limit: limit, modelId: rolesModelId
        }
        const resp = await getAllTestPlanService(obj);
        if (resp && resp.success) {
            let plans = resp.data && resp.data ? resp.data : [];
            if (planExecutionInfo.jobExecuted && planExecutionInfo.testPlanId) {
                plans = plans.map(job => job.testPlanMasterId == planExecutionInfo.testPlanId ? {
                    ...job, runStatus: "pending", percentage: 0,
                    completeCount: 0, lastDate: new Date()
                } : job)
                setPlanExecutionInfo({})
            }
            plans.sort((a, b) => new Date(b.lastDate) - new Date(a.lastDate));
            setTestPlans((prevData) => [...prevData, ...plans])
            if (plans.length === limit) {
                getAllTestPlan(page + 1)
            }
        }
        setIsLoading(false)
    }

    const getAIModels = async () => {
        const resp = await getAIModelService();
        if (resp && resp.success) {
            const defaultModel = resp.data.find(model => model.default);
            if (defaultModel) {
                setDefaultModel(defaultModel)
            }
        }
    }

    const runTestPlan = async (planId) => {
        if (isTestRunning) {
            return
        }
        setIsTestRunning(true)
        const obj = {
            testPlanMasterId: planId, aiModelId: defaultModel.model
        }
        toastInfo("Test Job is in progress, please wait until job gets completed!");
        manageRunningJobPlan(planId)
        const resp = await runTestPlanService(obj)
        if (resp && resp.success) {
            // // toastSuccess("Test Job completed successfully, Please find your result!");
            // setTestPlans([])
            // // setTestPlansRuns([])
            // getAllTestPlan(1)
            // // getAllTestPlanRunHistory()
        }
    }

    const manageRunningJobPlan = (planId) => {
        const updatedPlans = testPlans.map(job => job.testPlanMasterId == planId ? {
            ...job, runStatus: "pending", percentage: 0,
            completeCount: 0, lastDate: new Date()
        } : job);
        updatedPlans.sort((a, b) => new Date(b.lastDate) - new Date(a.lastDate));
        setTestPlans(updatedPlans)
    }

    useEffect(() => {
        let interval;
        setTimeout(() => {
            const pendingJobs = testPlans.filter((job) => job.runStatus.toLowerCase() === "pending");
            if (pendingJobs.length > 0 && rolesModelId) {
                interval = setInterval(async () => {
                    let runCount = 0;
                    for (const job of pendingJobs) {
                        try {
                            if (!job.percentage || job.percentage < 100) {
                                if(runCount !== 0){
                                    await new Promise((resolve) => setTimeout(resolve, 3000));
                                }
                                runCount = runCount+1;
                                const jobInfo = await getJobRunningStatus(job.testPlanMasterId);
                                if (jobInfo) {
                                    if (jobInfo.percentage === 100) {
                                        setIsTestRunning(false)
                                        setTestPlans((prevJobs) =>
                                            prevJobs.map((j) =>
                                                j.testPlanMasterId === jobInfo.testPlanMasterId ? { ...j, runStatus: "completed", completeCount: jobInfo.completed, percentage: jobInfo.percentage } : j
                                            )
                                        );
                                        // setTestPlans([])
                                        // await getAllTestPlan(1);

                                        clearInterval(interval);
                                        break;
                                    }
                                    else {
                                        setTestPlans((prevJobs) =>
                                            prevJobs.map((j) =>
                                                j.testPlanMasterId === jobInfo.testPlanMasterId ? { ...j, completeCount: jobInfo.completed, percentage: jobInfo.percentage } : j
                                            )
                                        );
                                    }
                                }
                            }
                            else {
                                clearInterval(interval);
                                break;
                            }

                        } catch (error) {

                        }
                    }
                }, 5000);
            }
        }, 500)

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [testPlans, rolesModelId]);

    const getJobRunningStatus = async (id) => {
        const resp = await testJobStatusService(id);
        if (resp && resp.success) {
            const jobStatus = resp.data;
            return {
                percentage: (jobStatus.completeCount * 100) / jobStatus.totalQuestions,
                completed: jobStatus.completeCount,
                testPlanRunId: jobStatus.testPlanRunId,
                testPlanMasterId: id,
            };
        }
        return null;
    };
    

    const navigateToSummaryPage = (info, viewType) => {
        const tableInfo = info.data;
        if (tableInfo.runStatus.toLowerCase() !== 'pending') {
            let obj = { viewType: viewType };
            if(viewType === 'SUMMARY'){
                obj.testPlanRunId = tableInfo.testPlanRunId;
            }
            nav(`/admin/testing-harness/summary/${tableInfo.testPlanMasterId}`, { state: obj })
        }
    }

    const reRunTest = (e, info) => {
        e.preventDefault();
        setRunningPlan(info)
    }

    const tableValues = (info, field, rowIndex) => {
        const tableInfo = info;

        if (tableInfo) {
            if (rowIndex && tableInfo.runStatus.toLowerCase() === 'pending' && rowIndex > 4 && rowIndex <= 9) {
                if (field === 'model') {
                    const percentage = tableInfo.percentage ? tableInfo.percentage : 2;
                    const completeCount = tableInfo.completeCount ? tableInfo.completeCount : 0;
                    const progressbarText = () => {
                        return `Completed: ${completeCount}`
                    }
                    return (
                        userMail === tableInfo.runBy ?
                            <div className="bl-progress-cell bl-testing-progress">
                                <ProgressBar value={percentage} displayValueTemplate={progressbarText} color="green" />
                            </div> : <div className="d-flex align-items-center">
                                <img src="https://i.gifer.com/origin/4a/4a287dd4b9222ebb17dc354257d0ef79_w200.gif" className="mr-2" height="13px" />
                                <p className="mb-0 text-muted">Test Run in-progress</p>
                            </div>
                    );
                } else if (['question', 'passRate', 'result', 'run'].includes(field)) {
                    return null;
                }
            }
            else {
                if (field === 'planName') {
                    return <div className="d-flex align-items-center"><i className="fa fa-fw fa-book mr-2" aria-hidden="true"></i> {tableInfo.testPlanName}</div>
                }
                else if (field === 'createdBy') {
                    return tableInfo.createdUserName ? tableInfo.createdUserName : tableInfo.createdBy;
                }
                else if (field === 'testedBy') {
                    return tableInfo.userName ? tableInfo.userName : '-'
                }
                else if (field === 'testRunDate') {
                    return tableInfo.runStatus !== 'saved' ? moment(tableInfo.lastDate).format('MMM DD, YYYY hh:mm A') : '-';
                }
                else if (field === 'runDate') {
                    return moment(tableInfo.runDate).format('MMM DD, YYYY hh:mm A')
                }
                else if (field === 'model') {
                    return tableInfo.displayName;
                }
                else if (field === 'question') {
                    return tableInfo.totalActiveQuestions;
                }
                else if (field === 'passCount') {
                    return tableInfo.passCount;
                }
                else if (field === 'failCount') {
                    return tableInfo.failCount;
                }
                else if (field === 'passRate') {
                    return tableInfo.runStatus === 'saved' ? '-' : `${Math.round((parseInt(tableInfo.passCount) * 100) / parseInt(tableInfo.totalTestRuns))}%`;
                }
                else if (field === 'passRatePercentage') {
                    return `${Math.round((parseInt(tableInfo.passCount) * 100) / parseInt(tableInfo.totalQuestions))}%`
                }
                else if (field === 'result') {
                    return <span className={`${tableInfo.result.toLowerCase().includes('pass') ? 'text-success' : 'text-danger'} text-center text-uppercase`}>{tableInfo.runStatus === 'saved' ? 'N/A' : tableInfo.result}</span>
                }
                else if (field === 'run') {
                    return <button className={`btn btn-outline-secondary btn-sm ${isTestRunning ? 'disabled' : ''}`} data-toggle="modal" data-target="#testrunalertmodal" onClick={(e) => reRunTest(e, tableInfo)}><i className="fa fa-fw fa-play" aria-hidden="true"></i> RUN</button>
                }
            }

        }
    };

    const allTestPlanContent = () => {
        return (
            <DataTable value={testPlans} rowClassName={"bl-source-overview-list position-relative"} className="cp table table-sm" selectionMode="single" onRowClick={(e) => navigateToSummaryPage(e, 'DETAILS')}>
                <Column field="testPlanName" header="Test Plan Name" body={(entity) => tableValues(entity, 'planName', 1)} sortable></Column>
                <Column field="createdUserName" header="Created By" body={(entity) => tableValues(entity, 'createdBy', 2)} sortable></Column>
                <Column field="userName" header="Last Run By" body={(entity) => tableValues(entity, 'testedBy', 3)} sortable></Column>
                <Column field="lastDate" header="Last Run Date" body={(entity) => tableValues(entity, 'testRunDate', 4)} dataType="date" sortable></Column>
                <Column field="displayName" header="Model" body={(entity) => tableValues(entity, 'model', 5)} sortable></Column>
                <Column field="totalActiveQuestions" header="Total Questions" className="text-center" body={(entity) => tableValues(entity, 'question', 6)} sortable></Column>
                <Column field="passCount" header="Pass Rate(%)" className="text-center" body={(entity) => tableValues(entity, 'passRate', 7)} sortable></Column>
                <Column field="result" header="Result" className="text-center" body={(entity) => tableValues(entity, 'result', 8)}></Column>
                <Column field="status" header="Run Test" className="text-center" body={(entity) => tableValues(entity, 'run', 9)}></Column>
            </DataTable>
        )
    }

    const getAllTestRunsHistoryContent = () => {
        return (
            <DataTable value={testPlansRuns} rowClassName={"bl-source-overview-list position-relative"} className="cp table table-sm" selectionMode="single" onRowClick={(e) => navigateToSummaryPage(e, 'SUMMARY')}>
                <Column field="testPlanName" header="Test Plan Name" body={(entity) => tableValues(entity, 'planName')} sortable></Column>
                <Column field="runBy" header="Last Run By" sortable></Column>
                <Column field="runDate" header="Last Run Date" body={(entity) => tableValues(entity, 'runDate')} dataType="date" sortable></Column>
                <Column field="displayName" header="Model" body={(entity) => tableValues(entity, 'model')} sortable></Column>
                <Column field="totalQuestions" header="Total Questions" className="text-center" sortable></Column>
                <Column field="passCount" header="Pass Count" className="text-center" body={(entity) => tableValues(entity, 'passCount')} sortable></Column>
                <Column field="failCount" header="Fail Count" className="text-center" body={(entity) => tableValues(entity, 'failCount')} sortable></Column>
                <Column field="passCount" header="Pass Rate(%)" className="text-center" body={(entity) => tableValues(entity, 'passRatePercentage')} sortable></Column>
                <Column field="result" header="Result" className="text-center" body={(entity) => tableValues(entity, 'result')} sortable></Column>
            </DataTable>
        )
    }

    const testRunConfirmationAlertContent = () => {
        return (
            <div className="modal fade" id="testrunalertmodal" tabIndex="-1" role="dialog" aria-labelledby="testrunalertmodallabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="testrunalertmodallabel">{reportForm.RUN.TITLE}</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {reportForm.RUN.INFO} "<b>{runningPlan.testPlanName}</b>"
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-primary" type="button" data-dismiss="modal">{buttonsLabel.CANCEL}</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => runTestPlan(runningPlan.testPlanMasterId)}>{buttonsLabel.YES}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-12">
                        <section>
                            <div className="media p-2">
                                <div className="media-body">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0">{labels.WELCOME_DESC}</p>
                                    <button type="button" className="btn btn-primary btn-sm my-3" onClick={() => nav('/admin/testing-harness/manage')}>Create New Test Plan</button>
                                </div>
                                <img className="bl-overview-img" src={'https://img.freepik.com/free-vector/businessmen-working-woman-big-target-with-arrow-goals-objectives-business-grow-plan-goal-setting-concept-white-background_335657-2048.jpg'} alt="overview-Img" />
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card-deck">
                                {
                                    testPlanKPI && testPlanKPI.map((kpi, i) => {
                                        return (
                                            <div key={`testing-harness-kpi-${i}`} className="card shadow-sm">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div><h6>{kpi.title}</h6></div>
                                                        {
                                                            kpi.icon ? <div className="icon-shape icon-md bg-light-primary text-primary rounded-2">
                                                                <i className={`fa fa-${kpi.icon} ${kpi.colorClass}`} aria-hidden="true"></i>
                                                            </div> : null
                                                        }
                                                    </div>
                                                    <div>
                                                        <h5 className={`mb-0 ${kpi.title.toLowerCase().includes('pass') ? 'text-success' : kpi.title.toLowerCase().includes('fail') ? 'text-danger' : ''}`}><b>{kpi.description}</b></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="border-bottom">
                                                <h6 className="mb-3">{labels.RECOMMEND}</h6>
                                                <div className="d-flex align-items-center mb-3">
                                                    <div className="d-flex">
                                                        <span className={`badge bl-chip-btn cp mr-2 ${viewType === 'PLANS' ? 'active' : ''}`} onClick={() => setViewType('PLANS')}>Active Test Plans</span>
                                                        <span className={`badge bl-chip-btn cp ${viewType === 'HISTORY' ? 'active' : ''}`} onClick={() => setViewType('HISTORY')}>All Test Run History</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isLoading ? <div className="col text-center">
                                                    <img className="bl-list-loader" src={listViewLoader} />
                                                </div> :
                                                    viewType === 'PLANS' ? allTestPlanContent() : getAllTestRunsHistoryContent()
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {testRunConfirmationAlertContent()}
            </div>

        </div>
    )
}