import React, { useContext, useEffect, useState } from "react";
import aiIcon from "./../../assets/icons/floatbtn.png";
import AIContext from "../../services/context/AIAssistance/aiContext";
import { AuthContext } from "../../services/context/Auth/authContext";
import { useLocation } from "react-router-dom";


export const FloatButton = () => {

    const { setAIModalType } = useContext(AIContext)
    const { applicableApps } = useContext(AuthContext)
    const location = useLocation()

    const [isAIEnabled, SetIsAIEnabled] = useState(false)
    const [isKairosApp, setIsKairosApp] = useState(false)

    useEffect(() => {
        const path = location.pathname.split('/');
        const pathname = path && path.length ? path[1] : '';
        const apps = ['kairos', 'admin', 'briefing-app', 'speaker-brief', 'dealbook']
        setIsKairosApp(apps.includes(pathname))
    }, [location])

    useEffect(() => {
        const aiModule = applicableApps.findIndex(app => app.appName === "KAIROS");
        SetIsAIEnabled(aiModule !== -1)
    }, [applicableApps])

    return (
        <div id="aifloatbtn" className="fab-container d-print-none d-none" onClick={() => setAIModalType('DEFAULT')} data-toggle="modal" data-target="#aimodalbox">
            {/* {
                isAIEnabled && !isKairosApp ? (
                    <div className="fab shadow" id="floatAIBtn">
                        <div className="fab-content">
                            <img src={aiIcon} height="40px" title="Ask AI" />
                        </div>
                    </div>
                ) : null
            } */}
        </div>
    )
}