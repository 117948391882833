import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { AnalyzeCompanyContext } from "../../services/context/analyzeCompanyContext";
import { SummarizerContext } from "../../services/context/summarizerContext";
import { analyzeNewProjectService, getAnalyzedProjectListService, getProjectProcessingStatusService, getTemplateListService } from "../../services/summarizerService";
import moment from "moment";
import { toastWarning } from "../../services/notifyService";

export const DealBookComponent = () => {

    const { projectList, setProjectList, viewType, setIsLoading } = useContext(SummarizerContext)
    const { analyzedCompanyInfo, setAnalyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const [retryCount, setRetryCount] = useState(0);
    const [intervalDelay, setIntervalDelay] = useState(5000);

    useEffect(() => {
        fetchCompanyList();
    }, [analyzedCompanyInfo, viewType]);

    const fetchCompanyList = async () => {
        setIsLoading(true)
        setProjectList([]);
        setTimeout(() => {
            getProjectList(1);
        }, 300)
    };

    const getProjectList = async (page) => {
        const limit = 30;
        const obj = {
            page: page, limit:limit, publishedStatus: viewType === "ALL" ? null : viewType
        }
        const resp = await getAnalyzedProjectListService(obj);
        getProjectListResponse(page, resp, limit);
        setIsLoading(false)
    };

    const getProjectListResponse = (page, resp, limit) => {
        if (resp && resp.success) {
            const newCompanyList = resp.data;
            setProjectList((companies) => [...companies, ...newCompanyList]);
            if (resp.data.length >= limit) {
                getProjectList(page + 1);
            }
        }
    };

    const getProjectProcessingStatus = async(id) => {
        try{
            const resp = await getProjectProcessingStatusService(id);
            if(resp && resp.success){
                return resp.data
            }
            return null
        }
        catch(e){
            console.log("Error ====>", e);
        }
    }

    useEffect(() => {
        // if (retryCount >= 15) {
        //     console.log("Max retry count reached. Stopping further retries.");
        //     return;
        // }

        const fetchCompanyData = async () => {
            let allInfoAvailable = true;
            const updatedCompanyList = await Promise.all(
                projectList.map(async (item) => {
                    const compDate = item.updated_at ? item.updated_at : moment();
                    const diffInHours = moment().diff(moment(compDate), 'hours', true)
                    if (Math.abs(diffInHours) < 1 && item.projectProcessingStatus.toLowerCase() === 'pending') {
                        allInfoAvailable = false;
                        try {
                            const info = await getProjectProcessingStatus(item.projectId);
                            if (info) {
                                return { ...item, ...info, reachedMaxLimit: retryCount === 4 };
                            }
                            if (retryCount === 4) {
                                return { ...item, reachedMaxLimit: true };
                            }
                        } catch (error) {
                            console.error(`Error fetching info for ID: ${item.id}`, error);
                        }
                    }
                    return item;
                })
            );
            if(retryCount >= 4){
                // setProjectList([])
                setTimeout(() => {
                    setProjectList(updatedCompanyList)
                }, 500)
            }
            else{
                setProjectList(updatedCompanyList);
            }            

            if (!allInfoAvailable) {
                setRetryCount((prev) => prev + 1);
                // setIntervalDelay((prev) => prev + 3000);
            }
        };

        const intervalId = setInterval(() => {
            fetchCompanyData();
        }, intervalDelay);

        return () => clearInterval(intervalId);
    }, [retryCount, intervalDelay, projectList]);

    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null)
    const [projectName, setProjectName] = useState('')
    const [templateList, setTemplateList] = useState([])
    const [templateId, setTemplateId] = useState("")

    const triggerFileInput = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    useEffect(() => {
        setTemplateId("")
        setTemplateList([])
        getTemplateList()
    }, [])

    const getTemplateList = async() => {
        try{
            const resp = await getTemplateListService();
            if (resp && resp.success) {
                const templateInfo = resp.data[0];
                setTemplateList(resp.data && resp.data.length ? resp.data : [])
                setTemplateId(templateInfo.templateId)
            }
        }
        catch(e){

        }
    }

    const handleFileChange = async (event) => {
        const selectedDocFile = event.target.files[0];
        if (selectedDocFile) {
            if (selectedDocFile.name && selectedDocFile.name.toLowerCase().endsWith('.pdf')) {
                setSelectedFile(selectedDocFile)
            } else {
                fileInputRef.current.value = '';
                alert('Please upload a valid PDF file.');
            }
            setSelectedFile(selectedDocFile)
        }
    };

    const analyzeNew = async (e) => {
        e.preventDefault()
        try{
            if (!projectName.trim()) {
                return toastWarning('Please enter your project name!')
            }
            if(!templateId){
                return toastWarning('Please select a template!')
            }
            if(!selectedFile){
                return toastWarning('Please choose your the file!')
            }
            document.getElementById('pageLoaderContainer').style.display = 'flex';
    
            const obj = new FormData();
            obj.append('file', selectedFile);
            obj.append('projectName', projectName)
            obj.append('templateId', templateId)
            const resp = await analyzeNewProjectService(obj);
            if (resp && resp.success) {
                setProjectName('')
                setTemplateId("")
                setSelectedFile(null)
                setAnalyzedCompanyInfo(resp.data)
                document.getElementById("dbFileSelection").value="";
                window.$('#analyzeNewModal').modal('hide');
            }
            else {
                alert("Not able to process, Please try again!")
            }
        }
        catch(e){

        }
        finally {
            document.getElementById('pageLoaderContainer').style.display = 'none';
        }
    }

    const analyzeNewCompanyContent = () => {
        return (
            <div className="modal fade" id="analyzeNewModal" tabIndex="-1" role="dialog" aria-labelledby="analyzeNewModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form onSubmit={(e) => analyzeNew(e)}>
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="analyzeNewModalLabel">Analyze New</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body py-0">
                                <div className="row">
                                    <div className="col-12 pt-3">
                                        <div className="form-group">
                                            <label>Project Name:</label>
                                            <input type="text" id="companySite" className="form-control" value={projectName} onChange={(e) => setProjectName(e.target.value)} placeholder="Enter a project name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Template:</label>
                                            <select className="form-control" value={templateId} onChange={(e) => setTemplateId(e.target.value)}>
                                                {
                                                    templateList && templateList.map((temp, i) => {
                                                        return (
                                                            <option key={`DB-templates-${i}`} value={temp.templateId}>{temp.templateName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="card text-center bl-db-fileupload-border">
                                                <div className="card-body">
                                                    <img src="https://icons.veryicon.com/png/o/business/cloud-computing-background-management-icon/upload-to-folder.png" height="80px" />
                                                    <p className="card-text">Click below to select a file from this device</p>
                                                    <input type="file" id="dbFileSelection" ref={fileInputRef} accept=".pdf" onChange={handleFileChange} className="d-none" />
                                                            <button className="btn btn-outline-primary btn-sm text-nowrap" onClick={triggerFileInput}><i className="fa fa-upload mr-2" aria-hidden="true"></i> Upload Now</button>
                                                            {
                                                                selectedFile && selectedFile.name ? <span className="ml-2">{selectedFile.name}</span> : null
                                                            }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <ul className="pl-3 font-small">
                                                <li className="text-muted font-italic">File processing usually takes around 8–10 minutes, depending on the file size or number of pages</li>
                                                <li className="text-muted font-italic">You can monitor the upload and processing progress from your dashboard</li>
                                                <li className="text-muted font-italic">Once processing is complete, you can click to view, update, or publish the generated summary</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-outline-primary secondary-link btn-sm btn-block py-2" type="button" data-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary btn-sm mt-0 btn-block py-2">Proceed</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
        <Outlet />
        {analyzeNewCompanyContent()}
        </>
    )
}