import apiInstance from "./serviceMaster";

export const generateSBEventId = (id) => {
    if (id && parseInt(id) > 0) {
        if (parseInt(id) < 10) {
            return `SB-00${id}`
        }
        else if (parseInt(id) >= 10 && parseInt(id) < 100) {
            return `SB-0${id}`
        }
        else {
            return `SB-${id}`
        }
    }

}

const speakerBriefEventsURL = "speaker-brief/events";
const speakerBriefModelsURL = "speaker-brief/models";

export const getSBEventListService = async (obj) => {
    let queryParams = "?";
    let itemArray = Object.keys(obj);
    for (let i = 0; i < itemArray.length; i++) {
        if (obj[itemArray[i]]) {
            queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
        }
    }
    queryParams = queryParams.slice(0, -1)
    return await apiInstance.get(`${speakerBriefEventsURL}${queryParams}`)
}

export const createNewSBEventsService = async(obj) => {
    return await apiInstance.post(`${speakerBriefEventsURL}`, obj)
}

export const getSBModelsService = async(obj) => {
    let queryParams = "?";
    let itemArray = Object.keys(obj);
    for (let i = 0; i < itemArray.length; i++) {
        if (obj[itemArray[i]]) {
            queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
        }
    }
    queryParams = queryParams.slice(0, -1)
    return await apiInstance.get(`${speakerBriefModelsURL}${queryParams}`)
}

export const getSBEventInformationService = async (obj, id) => {
    let queryParams = "?";
    let itemArray = Object.keys(obj);
    for (let i = 0; i < itemArray.length; i++) {
        if (obj[itemArray[i]]) {
            queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
        }
    }
    queryParams = queryParams.slice(0, -1)
    return await apiInstance.get(`${speakerBriefEventsURL}/${id}${queryParams}`)
}

export const deleteSBEventInformationService = async (id) => {
    return await apiInstance.delete(`${speakerBriefEventsURL}/${id}`)
}

export const getEventDetailsService = async(id) =>{
    return await apiInstance.get(`${speakerBriefEventsURL}/${id}/details`)
}

export const updateEventResponseQuestionSectionService = async(id, obj) =>{
    return await apiInstance.put(`${speakerBriefEventsURL}/${id}`, obj)
}

export const publishEventResponseService = async(id, obj) =>{
    return await apiInstance.patch(`${speakerBriefEventsURL}/${id}`, obj)
}

export const updateEventResponseFeedbackService = async(eventId, responseId, feedbackId, obj) =>{
    return await apiInstance.patch(`${speakerBriefEventsURL}/${eventId}/responses/${responseId}/feedback/${feedbackId}`, obj)
}

export const addEventResponseFeedbackService = async(eventId, responseId, obj) =>{
    return await apiInstance.post(`${speakerBriefEventsURL}/${eventId}/responses/${responseId}/feedback`, obj)
}

export const getEventStatusService = async(id) => {
    return await apiInstance.get(`${speakerBriefEventsURL}/${id}/status`)
}

export const getSBEventTypesService = async (obj) => {
    let queryParams = "?";
    let itemArray = Object.keys(obj);
    for (let i = 0; i < itemArray.length; i++) {
        if (obj[itemArray[i]]) {
            queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
        }
    }
    queryParams = queryParams.slice(0, -1)
    return await apiInstance.get(`${speakerBriefEventsURL}/types${queryParams}`)
}
