import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import homeLabel from "./../../../utils/property-file/componentOverview.json";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation, useNavigate } from "react-router-dom";
import listViewLoader from "./../../../assets/img/loading-animation.gif";
import { ModelEntityContext } from "../../../services/context/entityContext";
import moment from "moment";
import { validateAndFormatDate } from "../../../utils/validation";
import { generateModelTableId } from "../../../services/modelService";
import { generateSlug } from "../../../services/commonService";
import { AuthContext } from "../../../services/context/Auth/authContext";


export const DataEntityHomeComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { isSuperAdmin } = useContext(AuthContext)
    const { tableList, selectedDataSource, setSelectedDataSource, isLoading } = useContext(ModelEntityContext)
    const nav = useNavigate()
    const location = useLocation();

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/admin', menuName: 'Admin Console', isActive: true, hasSubMenu: true },
            { slug: '/admin/data-entity-configuration', menuName: 'Data Entity Configuration', isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: [],
        RECOMMEND: ""
    });
    const [entityKPI, setEntityKPI] = useState([])
    const [allDataEntity, setAllDataEntity] = useState([])
    const [dataEntity, setDataEntity] = useState([])
    const [searchEntity, setSearchEntity] = useState("")

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "DATA_ENTITY").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        setEntityKPI(labels.ENTITY)
    }, [labels])

    useEffect(() => {
        setAllDataEntity(tableList)
    }, [tableList])

    useEffect(() => {
        if (allDataEntity && allDataEntity.length) {
            const filteredEntity = allDataEntity.filter(entity => {
                return entity.datasetName.toLowerCase().includes(searchEntity.toLowerCase()) ||
                    (entity.databaseName && entity.databaseName.toLowerCase().includes(searchEntity.toLowerCase())) ||
                    (entity.name && entity.name.toLowerCase().includes(searchEntity.toLowerCase())) ||
                    (entity.schema && entity.schema.toLowerCase().includes(searchEntity.toLowerCase()));
            });

            setDataEntity(filteredEntity)
        }
        else {
            setDataEntity([])
        }
    }, [searchEntity, allDataEntity])

    const tableValues = (info, field) => {
        const tableInfo = info;
        if(tableInfo){
            if(field === 'dataset'){
                return <div className="d-flex align-items-center"><i className="fa fa-fw fa-database mr-2" aria-hidden="true"></i> {tableInfo.datasetName}</div>
            }
            else if(field === 'description'){
                return <div className="bl-truncate-line-2">{tableInfo.description}</div>
            }
            if (field === 'createdOn') {
                return validateAndFormatDate(tableInfo.createdOn);
            }
            else if (field === 'lastUpdatedOn') {
                return moment(tableInfo.createdOn).isSame(tableInfo.lastUpdatedOn) ? '' : validateAndFormatDate(tableInfo.lastUpdatedOn);
            }
            else if (field === 'enabled') {
                const state = tableInfo.status.toLowerCase() === 'completed' ? tableInfo.isEnabled ? 'Enabled' : 'Disabled' : 'Newly Added';
                return (
                   <div className={`text-capitalize`}><i className={`fa fa-circle mr-1 ${getTextColorForStatus(state)}`} aria-hidden="true"></i><b>{state}</b></div>
                )
            }
            else if (field === 'status') {
                const state = tableInfo.status.toLowerCase() === 'newly added' ? 'Pending' : tableInfo.status;
                return (
                   <div className={`text-capitalize ${getTextColorForStatus(state)}`}><b>{state}</b></div>
                )
            }
        }
    };

    const getTextColorForStatus = (status) => {
        if (status.toLowerCase() === 'pending') {
            return 'text-warning';
        }
        else if (status.toLowerCase() === 'newly added') {
            return 'text-info';
        }
        else if (status.toLowerCase() === 'disabled') {
            return 'text-secondary';
        }
        else if(status.toLowerCase() === 'enabled' || status.toLowerCase() === 'completed') {
            return 'text-success'
        }
        else {
            return ''
        }
    }

    const getEntitySections = (entity) => {
        const tableInfo = entity.data;
        const tableId = generateModelTableId(tableInfo.tableId);
        nav(`/admin/data-entity-configuration/${tableId}`)
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-12">
                        <section>
                            <div className="media p-2">
                                <div className="media-body">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0">{labels.WELCOME_DESC}</p>
                                    <button className="btn btn-primary btn-sm my-3" data-toggle="modal" data-target="#addtablemodal"><i className="fa fa-fw fa-plus-circle" aria-hidden="true"></i>Add New Dataset</button>
                                    {/* {
                                        isSuperAdmin ? <button className="btn btn-primary btn-sm my-3" data-toggle="modal" data-target="#addtablemodal"><i className="fa fa-fw fa-plus-circle" aria-hidden="true"></i>Add New Dataset</button> : null
                                    } */}
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src="https://img.freepik.com/free-vector/flat-design-data-driven-illustration_23-2149479220.jpg" alt="overview-Img" />
                            </div>
                        </section>

                        <section className="mb-3 d-none">
                            <div className="card-deck">
                                {
                                    entityKPI && entityKPI.map((kpi, i) => {
                                        return (
                                            <div key={`testing-harness-kpi-${i}`} className="card shadow-sm">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="text-muted"><h6>{kpi.title}</h6></div>
                                                        {
                                                            kpi.icon ? <div className="icon-shape icon-md bg-light-primary text-primary rounded-2 bl-db-kpi-fontsize">
                                                                <i className={`fa fa-${kpi.icon} ${kpi.colorClass}`} aria-hidden="true"></i>
                                                            </div> : null
                                                        }
                                                    </div>
                                                    <div>
                                                        <h5 className={`mb-0 ${kpi.title.toLowerCase().includes('pass') ? 'text-success' : kpi.title.toLowerCase().includes('fail') ? 'text-danger' : ''}`}><b>{kpi.description}</b></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="border-bottom">
                                                <h6 className="mb-3">{labels.RECOMMEND}</h6>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex">
                                                        <span className={`badge bl-chip-btn cp bl-sb-datatableTabs mr-2 ${selectedDataSource === 'snowflake'?'active':''}`} onClick={() => setSelectedDataSource('snowflake')}>Snowflake</span>
                                                        {/* <span className={`badge bl-chip-btn cp bl-sb-datatableTabs mr-2 disabled ${selectedDataSource === 'mysql'?'active':''}`} onClick={() => setSelectedDataSource('mysql')}>MySQL</span>
                                                        <span className={`badge bl-chip-btn cp bl-sb-datatableTabs disabled ${selectedDataSource === 'pitchbook'?'active':''}`} onClick={() => setSelectedDataSource('pitchbook')}>Pitchbook</span> */}
                                                    </div>
                                                    <div className="d-flex align-self-center">
                                                        <div className="input-group w-auto">
                                                            <input type="text" value={searchEntity} onChange={(e) => setSearchEntity(e.target.value)} className="form-control form-control-sm" placeholder="Search by name" />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="fa fa-search font-smaller"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-filter py-3 d-none">
                                                    <div className="col-xl-12 col-md-12">
                                                        <div className="form-row blc_button align-items-end">
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="inputState">Industry</label>
                                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                                    <option>All...</option>
                                                                    <option>...</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="emprangestart">Total Employee Range</label>
                                                                <div className="d-flex">
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem mr-1 blc_button" id="emprangestart" placeholder="From" />
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem blc_button" id="emprangeend" placeholder="To" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="scorerangestart">Score Range</label>
                                                                <div className="d-flex">
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem mr-1 blc_button" id="scorerangestart" placeholder="From" />
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem blc_button" id="scorerangeend" placeholder="To" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="ebitdarangestart">Adj EBITDA</label>
                                                                <div className="d-flex">
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem mr-1 blc_button" id="ebitdarangestart" placeholder="From" />
                                                                    <input type="text" className="form-control form-control-sm bl-w-5rem blc_button" id="ebitdarangeend" placeholder="To" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mr-2 mb-0">
                                                                <label htmlFor="ebitdarangestart">Date Range</label>
                                                                <div className="d-flex">
                                                                    <input type="date" className="form-control form-control-sm bl-w-5rem mr-1 blc_button" id="ebitdarangestart" placeholder="From" />
                                                                    <input type="date" className="form-control form-control-sm bl-w-5rem blc_button" id="ebitdarangeend" placeholder="To" />
                                                                </div>
                                                            </div>

                                                            <button className="btn btn-filter btn-sm">Apply Filter</button>
                                                            <button className="btn btn-link btn-sm bl-filter-clear">Clear All</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isLoading ? <div className="col text-center">
                                                    <img className="bl-list-loader" src={listViewLoader} />
                                                </div> : (
                                                    <DataTable value={dataEntity} rowClassName={"bl-source-overview-list position-relative"} className="cp table table-sm" selectionMode="single" onRowClick={getEntitySections}>
                                                        <Column field="datasetName" header="Dataset Name" body={(entity) => tableValues(entity, 'dataset')} sortable></Column>
                                                        <Column field="databaseName" header="Database" sortable></Column>
                                                        <Column field="schema" header="Schema" sortable></Column>
                                                        <Column field="displayName" header="Model" sortable></Column>
                                                        <Column field="createdOn" header="Added On" body={(entity) => tableValues(entity, 'createdOn')} sortable></Column>
                                                        {/* <Column field="lastModifiedBy" header="Model Tuning" body={(entity) => tableValues(entity, 'MODIFIED_BY', 4)} sortable></Column> */}
                                                        {/* <Column field="lastUpdatedOn" header="Last Tuned On" dataType="date" body={(entity) => tableValues(entity, 'MODIFY', 5)} sortable></Column> */}
                                                        {/* <Column field="createdBy" header="Total Rows" body={(entity) => tableValues(entity, 'CREATED_BY', 6)} sortable></Column> */}
                                                        <Column field="lastUpdatedOn" header="Last Updated On" dataType="date" body={(entity) => tableValues(entity, 'lastUpdatedOn')} sortable></Column>
                                                        <Column field="description" header="Description" body={(entity) => tableValues(entity, 'description')} sortable></Column>
                                                        {/* <Column field="description" header="Data Dictionary" className="text-center" body={(entity) => tableValues(entity, 'PAGE', 8)}></Column> */}
                                                        {/* <Column field="size" header="Configuration Added" className="text-center" body={(entity) => tableValues(entity, 'SIZE', 9)}></Column> */}
                                                        <Column field="status" header="Status" className="text-center" body={(entity) => tableValues(entity, 'status')}></Column>
                                                        {/* <Column field="status" header="Available" body={(entity) => tableValues(entity, 'enabled')}></Column> */}
                                                    </DataTable>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}