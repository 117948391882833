import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { addNewTableService, getDataSourceInfoService, getDataSourceTableListService, getModelListService, getRoleBasedModelsService, getUnAddedDatasetsService } from "../../../services/modelService";
import { ModelEntityContext } from "../../../services/context/entityContext";
import { toastError, toastInfo, toastSuccess } from "../../../services/notifyService";
import AIContext from "../../../services/context/AIAssistance/aiContext";


export const DataEntityComponent = () => {

    const { selectedDataSource, setTableList, setIsLoading } = useContext(ModelEntityContext)
    const { rolesModelId } = useContext(AIContext)
    const location = useLocation()
    const [databaseName, setDatabaseName] = useState([])
    const [schema, setSchema] = useState([])
    const [selectedDatabase, setSelectedDatabase] = useState("")
    const [selectedSchema, setSelectedSchema] = useState("")
    const [tableName, setTableName] = useState("")
    const [selectedModelId, setSelectedModelId] = useState("")
    const [models, setModels] = useState([])
    const [datasets, setDatasets] = useState([])
    const [isDatasetsLoading, setIsDatasetsLoading] = useState(false)

    useEffect(() => {
        setModels([])
        getModelList(1)
        getDataSourceInfo()
    }, [])

    useEffect(() => {
        if(rolesModelId){
            initiateTables()
        }
    }, [location.pathname, selectedDataSource, rolesModelId])

    const initiateTables = () => {
        setSelectedDatabase("")
        setSelectedSchema("")
        setSelectedModelId("")
        setTableName("")
        setTableList([])
        setDatasets([])
        setIsLoading(true)
        setTimeout(() => {
            getDataSourceTables(1)
        }, 300)
    }

    const getDataSourceTables = async (page) => {
        try {
            const limit = 30;
            const obj = {
                page: page, limit: limit, dataSource: selectedDataSource, modelId: rolesModelId
            }
            const resp = await getDataSourceTableListService(obj);
            if (resp && resp.success) {
                const _tableList = resp.data;
                setTableList((tables) => [...tables, ..._tableList]);
                setIsLoading(false)
                if (resp.data.length >= limit) {
                    getDataSourceTables(page + 1);
                }
            }
        }
        catch (e) {

        }
    }

    const getModelList = async (page) => {
        try {
            const limit = 30;
            const obj = {
                page: page, limit: limit
            }
            const resp = await getRoleBasedModelsService(obj);
            if (resp && resp.success) {
                const modelList = resp.data;
                setModels((model) => [...model, ...modelList]);
                if (modelList.length >= limit) {
                    getModelList(page + 1);
                }
            }
        }
        catch (e) {

        }
    }

    const getDataSourceInfo = async (page = 1) => {
        const limit = 30;
        const obj = {
            page: page, limit: limit
        }
        const resp = await getDataSourceInfoService(obj);
        if (resp && resp.success) {
            const listItems = resp.data;
            const _databaseName = [...new Set(listItems.map(item => item.databaseName))];
            const _schema = [...new Set(listItems.map(item => item.schema))];
            setDatabaseName(_databaseName)
            setSchema(_schema)
            // setSelectedDatabase(_databaseName.length > 0 ? _databaseName[0] : "")
            // setSelectedSchema(_schema.length > 0 ? _schema[0] : "")
        }
    }

    useEffect(() => {
        if (selectedDataSource && selectedSchema) {
            setDatasets([])
            getUnAddedDatasets()
        }
    }, [selectedSchema, selectedDataSource])

    const getUnAddedDatasets = async () => {
        try {
            const obj = {
                schema: selectedSchema, dataSource: selectedDataSource
            }
            setIsDatasetsLoading(true)
            const resp = await getUnAddedDatasetsService(obj);
            if (resp && resp.success) {
                if (resp.data.length === 0) {
                    toastInfo("There are no datasets available to add.")
                }
                setDatasets(resp.data)
            }
            setIsDatasetsLoading(false)
        }
        catch (e) {
            setIsDatasetsLoading(false)
        }
    }

    const addTable = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (!selectedDatabase.trim()) {
                return toastInfo("Please select a database!")
            }
            if (!selectedSchema.trim()) {
                return toastInfo("Please select a schema!")
            }
            if (!selectedModelId.trim()) {
                return toastInfo("Please select a model!")
            }
            if (!tableName.trim()) {
                return toastInfo("Please select the dataset!")
            }
            const obj = {
                tableName: tableName.trim(),
                databaseName: selectedDatabase,
                schema: selectedSchema,
                modelId: selectedModelId
            }
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            const resp = await addNewTableService(obj);
            if (resp && resp.success) {
                window.$("#addtablemodal").modal('toggle');
                toastSuccess("Table added successfully!");
                initiateTables()
            }
            else {
                const message = resp && resp.message ? resp.message : "An error occurred. Please try again!";
                toastError(message)
            }
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
        catch (e) {
            const message = e && e.data && e.data.message ? e.data.message : "An error occurred. Please try again!";
            toastError(message)
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const addTableModalContent = () => {
        return (
            <div className="modal fade" id="addtablemodal" tabIndex="-1" role="dialog" aria-labelledby="addTableModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addTableModalLabel">Add New dataset</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row mb-4">
                                    <div className="col d-flex justify-content-between">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="dataSources" id="exampleRadios1" value="Snowflake" defaultChecked />
                                            <label className="form-check-label" htmlFor="exampleRadios1">
                                                Snowflake
                                            </label>
                                        </div>
                                        <div className="form-check d-none">
                                            <input className="form-check-input" type="radio" name="dataSources" id="exampleRadios2" value="MySQL" disabled />
                                            <label className="form-check-label" htmlFor="exampleRadios2">
                                                MySQL
                                            </label>
                                        </div>
                                        <div className="form-check d-none">
                                            <input className="form-check-input" type="radio" name="dataSources" id="exampleRadios3" value="Pitchbook" disabled />
                                            <label className="form-check-label" htmlFor="exampleRadios3">
                                                Pitchbook
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Database:</label>
                                            <select className="form-control" id="database" value={selectedDatabase} onChange={(e) => setSelectedDatabase(e.target.value)}>
                                                <option value="" disabled>Select Database</option>
                                                {
                                                    databaseName && databaseName.length && databaseName.map((db, index) => {
                                                        return <option key={`db-op-${index}`} value={db}>{db}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Schema:</label>
                                            <select className="form-control" id="database" value={selectedSchema} onChange={(e) => setSelectedSchema(e.target.value)}>
                                                <option value="" disabled>Select Schema</option>
                                                {
                                                    schema && schema.length && schema.map((sc, index) => {
                                                        return <option key={`schema-op-${index}`} value={sc}>{sc}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Dataset:</label>
                                            <select className="form-control" id="database" value={tableName} onChange={(e) => setTableName(e.target.value)}>
                                                <option value="" disabled>Select Dataset</option>
                                                {
                                                    datasets && datasets.length && datasets.map((view, index) => {
                                                        return <option key={`datasets-op-${index}`} value={view.TABLE_NAME}>{view.TABLE_NAME}</option>
                                                    })
                                                }
                                                {
                                                    isDatasetsLoading ?
                                                        <option disabled>Please wait, fetching datasets...</option> :
                                                        datasets && datasets.length === 0 ? <option disabled>No Datasets found</option> : null
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Map Model ID:</label>
                                            <select className="form-control" id="database" value={selectedModelId} onChange={(e) => setSelectedModelId(e.target.value)}>
                                                <option value="" disabled>Select Model</option>
                                                {
                                                    models && models.length && models.map((model, index) => {
                                                        return <option key={`model-op-${index}`} value={model.modelId}>{model.displayName}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => addTable(e)}>Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Outlet />
            {addTableModalContent()}
        </>
    )
}