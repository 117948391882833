import apiInstance from "./serviceMaster";
const kairosAPPAPIKey = "428b5e8aefb41503b5977802be310f878c329cdc25";

const appConfigURL = "/auth/config";
const authenticationUrl = "/auth/authenticate";
const sessionUrl = "/auth/validate/session";
const logoutURL = "/auth/logout";
const appURL = "/application";
const tableInfoURL = "/auth/table/info";

export async function testSecurityService() {
    try {
        return apiInstance.get('/auth/test/security');
    }
    catch (e) {
        return e;
    }
}

export async function getApplicationInfo() {
    try {
        return apiInstance.get(appConfigURL);
    }
    catch (e) {
        return e;
    }
}

export async function authenticateByCodeService(code) {
    try {
        const { protocol, hostname } = window.location;
        const redirectURI = `${protocol}//${hostname}`
        const orgView = getOrgView();
        return await apiInstance.get(`${authenticationUrl}?code=${code}&orgThemeId=${orgView}&redirectURI=${redirectURI}`)
    }
    catch (e) {
        return e;
    }
}

export async function authenticateByCreadentialService(credential) {
    try {
        return await apiInstance.post(authenticationUrl, credential)
    }
    catch (e) {
        return e;
    }
}

export const authenticateKairosAppForIframeService = async () => {
    try {
        return await apiInstance.get('auth/token?userName=xyz@brownloop.com', {
            headers: {
                'api-key': kairosAPPAPIKey
            },
        });
    }
    catch (e) {
        return e;
    }
}

export async function validateSessionService() {
    try {
        return await apiInstance.get(sessionUrl)
    }
    catch (e) {
        return e;
    }
}

export async function storeToken(token) {
    localStorage.setItem("token", token)
}

export function getToken() {
    return localStorage.getItem("token")
}

export function removeToken() {
    window.$('.modal').modal('hide');
    localStorage.clear()
}

export async function storeUserInfo(info) {
    localStorage.setItem("user", info)
}

export function getUserInfo() {
    return localStorage.getItem("user")
}

export async function storeAppBehaviour(info) {
    localStorage.setItem("behaviour", info)
}

export function getAppBehavior() {
    return localStorage.getItem("behaviour")
}

export async function storeOrgView(info) {
    localStorage.setItem("orgView", info)
}

export function getOrgView() {
    return localStorage.getItem("orgView")
}

export async function storeCIMSummarizerProjectSections(info) {
    localStorage.setItem("projectSections", JSON.stringify(info))
}

export function getCIMSummarizerProjectSections() {
    return localStorage.getItem("projectSections")
}

export async function logoutService() {
    try {
        return await apiInstance.get(logoutURL)
    }
    catch (e) {
        return e;
    }
}

export const getRoleBasedAppService = async () => {
    try {
        return await apiInstance.get(appURL)
    }
    catch (e) {
        return e;
    }
}


export const getTableInfoService = async (obj) => {
    try {
        return await apiInstance.post(tableInfoURL, obj)
    }
    catch (e) {
        return e;
    }
}