import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { PageLayout } from './Components/pageLayout';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import LoginComponent from './Components/login';
import { PageNotFoundComponent } from './Components/pageNotFound';
import AIAssistanceContextProvider from './services/context/AIAssistance/aiContextProvider';
import BreadcrumbContextProvider from './services/context/Breadcrumb/breadcrumbContextProvider';
import SearchContextProvider from './services/context/Search/searchContextProvider';
import SystemDownCompoent from './Components/Errors/systemdown';
import { AuthContextProvider } from './services/context/Auth/authContext';
import { AnalyzeCompanyContextProvider } from './services/context/analyzeCompanyContext';
import { HomePageComponent } from './Components/home';
import { ToastContainer } from 'react-toastify';
import { DashboardComponent } from './Components/dashboard';
import { AnalyticsOverviewComponent } from './Components/Analytics/overview';
import { AnalyticsReportListComponent } from './Components/Analytics/reportList';
import { AnalyticsSelfServiceComponent } from './Components/Analytics/selfService';
import { AnalyticsCatalogComponent } from './Components/Analytics/catalog';
import { AnalyticsManageReportComponent } from './Components/Analytics/configure/manageReport';
import { AnalyticsManageReportCategoryComponent } from './Components/Analytics/configure/manageReportCategory';
import { AnalyticsViewReportComponent } from './Components/Analytics/reportView';
import AuthValidationComponent from './Components/authValidation';
import { UnauthorizeAccessComponent } from './Components/unauthorizeAccess';
import { TutorialComponent } from './Components/tutorial';
import { LogServiceComponent } from './Components/logs';
import { PDFViewerComponent } from './Components/PDFViewer';
import ThemeProvider from './services/context/Theme/themeProvider';
import { AIModalBodyComponent } from './Components/AIAssistant/AIModalBody';
import { AdminConsoleComponent } from './Components/Admin';
import { TestingHarnessComponent } from './Components/Admin/testingHarness';
import { ManageTestPlanComponent } from './Components/Admin/testingHarness/TestPlan/manage';
import { TestPlanComponent } from './Components/Admin/testingHarness/TestPlan';
import { BriefingAppComponent } from './Components/BriefingApp';
import { DealBookComponent } from './Components/Dealbook';
import { DealBookDashboardComponent } from './Components/Dealbook/dashboard';
import { DealbookCompanyManageSections } from './Components/Dealbook/Company/manageSections';
import { DealbookCompanyProfile } from './Components/Dealbook/Company/profileInfo';
import { SummarizerContextProvider } from './services/context/summarizerContext';
import { DealbookCompanyFiles } from './Components/Dealbook/Company/filesTags';
import { DealbookConfigTemplateComponent } from './Components/Dealbook/Templates/templateConfig';
import { DealBookProjectIndexComponent } from './Components/Dealbook/Company';
import { SpeakerBriefIndexComponent } from './Components/SpeakerBrief';
import { SpeakerBriefHomeComponent } from './Components/SpeakerBrief/dashboard';
import { SpeakerBriefContextProvider } from './services/context/speakerBriefContext';
import { SpeakerBriefConfigurationComponent } from './Components/SpeakerBrief/configuration';
import { SpeakerBriefEventsIndexComponent } from './Components/SpeakerBrief/Events';
import { SpeakerBriefManageEventsComponent } from './Components/SpeakerBrief/Events/manageEvents';
import { SpeakerBriefEventInformation } from './Components/SpeakerBrief/Events/eventInfo';
import { DataEntityComponent } from './Components/Admin/dataEntity';
import { DataEntityHomeComponent } from './Components/Admin/dataEntity/home';
import { DataEntityManageSectionsComponent } from './Components/Admin/dataEntity/manageEntity/sections';
import { ModelEntityContextProvider } from './services/context/entityContext';
import { DataEntityManageComponent } from './Components/Admin/dataEntity/manageEntity';
import { TestingHarnessHomeComponent } from './Components/Admin/testingHarness/home';
import { TestPlanInfoComponent } from './Components/Admin/testingHarness/TestPlan/testPlanInfo';
import { DealBookTemplates } from './Components/Dealbook/Templates';
import { DealBookTemplatesHomeComponent } from './Components/Dealbook/Templates/home';

function App() {

  return (
    <Router>
      <ThemeProvider>
        <AuthContextProvider>
          <BreadcrumbContextProvider>
            <SearchContextProvider>
              <AIAssistanceContextProvider>
                <AnalyzeCompanyContextProvider>
                  <SummarizerContextProvider>
                    <SpeakerBriefContextProvider>
                      <ModelEntityContextProvider>
                        <Routes>
                          <Route element={<PageLayout />}>
                            <Route path='/home' element={<HomePageComponent />} />
                            <Route path='/dashboard' element={<DashboardComponent />} />
                            <Route path='/log' element={<LogServiceComponent />} />
                            <Route path='/analytics/overview' element={<AnalyticsOverviewComponent />} />
                            <Route path='/analytics/reports/:id' element={<AnalyticsReportListComponent />} />
                            <Route path='/analytics/reports/category/:categoryId/:reportId' element={<AnalyticsReportListComponent />} />
                            <Route path='/analytics/self-service' element={<AnalyticsSelfServiceComponent />} />
                            <Route path='/analytics/catalog' element={<AnalyticsCatalogComponent />} />
                            <Route path='/analytics/manage-report' element={<AnalyticsManageReportComponent />} />
                            <Route path='/analytics/manage-report-category' element={<AnalyticsManageReportCategoryComponent />} />
                            <Route path='/analytics/reports/:catId/:reportId' element={<AnalyticsViewReportComponent />} />
                            <Route path='/analytics/reports/:catId/:subCatId/:reportId' element={<AnalyticsViewReportComponent />} />
                            <Route path='/kairos' element={<AIModalBodyComponent />} />
                            <Route path='/pdf/:company' element={<PDFViewerComponent />} />
                            <Route path='/unauthorize' element={<UnauthorizeAccessComponent />} />
                            <Route element={<AdminConsoleComponent />}>
                              <Route element={<TestingHarnessComponent />}>
                                <Route path='/admin/testing-harness' element={<TestingHarnessHomeComponent />} />
                                <Route element={<TestPlanComponent />}>
                                  <Route path='/admin/testing-harness/manage' element={<ManageTestPlanComponent />} />
                                  <Route path='/admin/testing-harness/manage/:id' element={<ManageTestPlanComponent />} />
                                  <Route path='/admin/testing-harness/summary/:id' element={<TestPlanInfoComponent />} />
                                </Route>
                              </Route>
                              <Route element={<DataEntityComponent />} >
                                <Route path='/admin/data-entity-configuration' element={<DataEntityHomeComponent />} />
                                <Route element={<DataEntityManageComponent />}>
                                  <Route path='/admin/data-entity-configuration/:id' element={<DataEntityManageSectionsComponent />} />
                                </Route>
                              </Route>
                            </Route>
                            <Route path='/briefing-app' element={<BriefingAppComponent />} />
                            <Route path='/dealbook' element={<DealBookComponent />} >
                              <Route path='/dealbook/home' element={<DealBookDashboardComponent />} />
                              <Route path='/dealbook/project' element={<DealBookProjectIndexComponent />}>
                                <Route path='/dealbook/project/:slug' element={<DealbookCompanyProfile />} />
                                <Route path='/dealbook/project/:slug/manage' element={<DealbookCompanyManageSections />} />
                                <Route path='/dealbook/project/:slug/files' element={<DealbookCompanyFiles />} />
                              </Route>
                              <Route element={<DealBookTemplates />}>
                                <Route path='/dealbook/templates' element={<DealBookTemplatesHomeComponent />} />
                                <Route path='/dealbook/templates/:id' element={<DealbookConfigTemplateComponent />} />
                              </Route>
                            </Route>
                            <Route path='/speaker-brief' element={<SpeakerBriefIndexComponent />}>
                              <Route path='/speaker-brief/dashboard' element={<SpeakerBriefHomeComponent />} />
                              <Route path='/speaker-brief/configuration' element={<SpeakerBriefConfigurationComponent />} />
                              <Route path='/speaker-brief/events' element={<SpeakerBriefEventsIndexComponent />}>
                                <Route path='/speaker-brief/events/manage' element={<SpeakerBriefManageEventsComponent />} />
                                <Route path='/speaker-brief/events/:id/info' element={<SpeakerBriefEventInformation />} />
                                <Route path='/speaker-brief/events/:id/event-details' element={<SpeakerBriefManageEventsComponent />} />
                              </Route>
                            </Route>
                          </Route>
                          <Route path='/' element={<AuthValidationComponent />} />
                          <Route path='/login' element={<LoginComponent />} />
                          <Route path='/system-down' element={<SystemDownCompoent />} />
                          <Route path='/tutorial' element={<TutorialComponent />} />
                          <Route path='/*' element={<PageNotFoundComponent />} />
                        </Routes>
                        <ToastContainer theme="colored" />
                      </ModelEntityContextProvider>
                    </SpeakerBriefContextProvider>
                  </SummarizerContextProvider>
                </AnalyzeCompanyContextProvider>
              </AIAssistanceContextProvider>
            </SearchContextProvider>
          </BreadcrumbContextProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
