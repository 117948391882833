import React, { useContext, useEffect, useState } from "react";
import testIcon from "./../../../assets/icons/testIcon.svg";
import templateLabel from "../../../utils/property-file/dealbook/template.json";
import buttonsLabel from "../../../utils/property-file/buttons-label.json";
import { useLocation, useNavigate } from "react-router-dom";
import listViewLoader from "./../../../assets/img/loading-animation.gif";
import { addNewSubSectionService, getTemplateInfoService, getTemplateListService, removeCustomSubSectionService, updateTemplateSubCategoryPromptsService } from "../../../services/summarizerService";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { toastError, toastSuccess, toastWarning } from "../../../services/notifyService";
import { generateReadableId } from "../../../utils/validation";


export const DealbookConfigTemplateComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const nav = useNavigate()
    const location = useLocation()

    const [action, setAction] = useState('NEW')
    const [templateName, setTemplateName] = useState('')
    const [breadcrumbName, setBreadcrumbName] = useState('')
    const [breadcrumbId, setBreadcrumbId] = useState('')
    const [templateDescription, setTemplateDescription] = useState('')
    const [keyPromptsSec] = useState({
        key: "", prompts: ""
    })
    const [keyPrompts, setKeyPrompts] = useState([keyPromptsSec])

    const [isLoading, setIsLoading] = useState(false)
    const [sections, setSections] = useState([])
    const [subSections, setSubSections] = useState([])
    const [templateId, setTemplateId] = useState(null)
    const [isScorecardSection, setIsScorecardSection] = useState(false)
    const [selectedSectionId, setSelectedSectionId] = useState(null)
    const [subSectionKey, setSubSectionKey] = useState("")
    const [subSectionPrompt, setSubSectionPrompt] = useState("")
    const [subSectionInfo, setSubSectionInfo] = useState({
        categoryId: null, name: "", parentId: null
    })
    const [selectedSection, setSelectedSection] = useState(null)
    const [sectionName, setSectionName] = useState("")

    useEffect(() => {
        setIsLoading(false)
        setTemplateName('')
        setTemplateDescription('')
        setTemplateId(null)
        getTemplateIdFromPath()
    }, [location.pathname])

    useEffect(() => {
        setAction(breadcrumbId === 'configure' ? "NEW" : "UPDATE")
        const pathId = breadcrumbId === 'configure' ? 'configure' : generateReadableId(templateId, 'TP');
        const pathName = breadcrumbId === 'configure' ? 'Configure' : templateName;
        setBreadcrumbMenu([
            { slug: '/dealbook', menuName: 'DealBook', isActive: true, hasSubMenu: true },
            { slug: `/dealbook/templates`, menuName: "Templates", isActive: false, hasSubMenu: true },
            { slug: `/dealbook/templates/${pathId}`, menuName: pathName, isActive: false, hasSubMenu: true }
        ])
    }, [breadcrumbId, templateId, templateName])

    const getTemplateIdFromPath = () => {
        console.log("Loca ===>", location);

        const pathArray = location.pathname.split('/');
        const pathTemplateId = pathArray[3] ? pathArray[3] === "configure" ? location.state.templateId : pathArray[3].split('-')[1] : null;
        if (pathTemplateId) {
            setBreadcrumbId(pathArray[3] === "configure" ? 'configure' : pathTemplateId)
            setTemplateId(parseInt(pathTemplateId))
        }
    }

    useEffect(() => {
        if (templateId) {
            getTemplateInfo()
        }
    }, [templateId])

    const getTemplateInfo = async () => {
        setIsLoading(true)
        const resp = await getTemplateInfoService(templateId);
        setIsLoading(false)
        if (resp && resp.success) {
            console.log("categories[0] ===>", resp.data);

            const templateInfo = resp.data.template;
            setTemplateName(templateInfo.templateName)
            setTemplateDescription(templateInfo.templateDescription)
            templateInfo.categories[0].selected = true;
            setSections(templateInfo.categories)
            setSelectedSection(templateInfo.categories[0].categoryId)
            setSubSections(templateInfo.categories[0].subCategories)
            setSelectedSectionId(templateInfo.categories[0].categoryId)
            setIsScorecardSection(!!(templateInfo.categories[0].sectionIdentifier && templateInfo.categories[0].sectionIdentifier === "scoreCard"))
        }
    }

    const updateTemplateList = async () => {
        const prevSecPrompts = subSections.map(subSec => ({ ...subSec }));
        const updatedSections = sections.map(secItem => {
            return {
                ...secItem,
                subCategories: secItem.categoryId === prevSecPrompts[0]?.parentId ? prevSecPrompts : secItem.subCategories
            };
        });
        const updateInfo = updatedSections.flatMap(sec =>
            sec.subCategories.map(sub => ({
                categoryId: sub.categoryId,
                prompt: sub.prompt
            }))
        );
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex';
        }
        const resp = await updateTemplateSubCategoryPromptsService({ prompts: updateInfo }, templateId)
        if (resp && resp.success) {
            toastSuccess("Prompts updated successfully!")
        }
        if (pageLoader) {
            pageLoader.style.display = 'none';
        }
    };

    const changeSelectedSection = (sec, index) => {
        console.log("get section info ===>", sec);
        if (sec) {
            setIsScorecardSection(!!(sec.sectionIdentifier && sec.sectionIdentifier === "scoreCard"))
            setSelectedSectionId(sec.categoryId)
        }
        const prevSecPrompts = subSections.map(subSec => ({ ...subSec }));
        let updatedSections = sections.map(secItem => {
            return {
                ...secItem,
                selected: false,
                subCategories: secItem.categoryId === prevSecPrompts[0]?.parentId ? prevSecPrompts : secItem.subCategories
            };
        });
        updatedSections[index].selected = true;
        setSelectedSection(updatedSections[index].categoryId)
        const subSectionsInfo = updatedSections[index].subCategories || [];
        setSections(updatedSections);
        setSubSections([])
        setTimeout(() => {
            setSubSections([...subSectionsInfo]);
        }, 400)
    };


    const handleInputChange = (sectionIndex, promptIndex, value) => {
        setSubSections((prevSubSections) => {
            const updatedSubSections = [...prevSubSections];
            if (promptIndex !== undefined) {
                updatedSubSections[sectionIndex] = {
                    ...updatedSubSections[sectionIndex],
                    prompt: updatedSubSections[sectionIndex].prompt.map((info, i) =>
                        i === promptIndex ? { ...info, prompt: value } : info
                    ),
                };
            } else {
                updatedSubSections[sectionIndex] = {
                    ...updatedSubSections[sectionIndex],
                    prompt: value,
                };
            }
            return updatedSubSections;
        });
    };

    useEffect(() => {
        console.log("Check sections list =====>", sections);
        console.log("Check sub sections list =====>", subSectionInfo);
    }, [subSectionInfo, sections])

    const clearSubSectionForm = () => {
        setSubSectionKey("")
        setSubSectionPrompt("")
    }

    const addNewSubSection = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (!subSectionKey.trim()) {
                return toastWarning("Please enter the key name!")
            }
            if (!subSectionPrompt.trim()) {
                return toastWarning("Please enter the prompts!")
            }
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            const obj = {
                name: subSectionKey, prompt: subSectionPrompt
            }
            const resp = await addNewSubSectionService(templateId, selectedSectionId, obj);
            if (resp && resp.success) {
                getTemplateInfo();
                window.$("#addSubSectionModal").modal("toggle");
                toastSuccess("Section added successfully!")
            }
        }
        catch (e) {
            const message = e && e.data && e.data.message ? e.data.message : "An error occurred. Please try again!";
            toastError(message)
        }
        finally {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const addNewSection = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (!sectionName.trim()) {
                return toastWarning("Please enter the section name!")
            }
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            const obj = {
                name: sectionName
            }
            const newSection = {
                categoryDescription: null,
                categoryId: sections.length + 1,
                customSection: true,
                name: sectionName,
                parentId: null,
                parentPosition: sections.length + 1,
                positionInParent: null,
                prompt: null,
                selected: true
            }
            setSelectedSection(sections.length + 1)
            const updatedSections = sections.map(secItem => {
                return {
                    ...secItem,
                    selected: false
                };
            });
            setSections([...updatedSections, newSection])
            
            // updatedSections[index].selected = true;
            
            // setSections(updatedSections);
            setSubSections([])
            setSectionName("")
            window.$("#addNewSectionModal").modal("toggle");
            // const resp = await addNewSubSectionService(templateId, selectedSectionId, obj);
            // if (resp && resp.success) {
            //     getTemplateInfo();
            //     window.$("#addSubSectionModal").modal("toggle");
            //     toastSuccess("Section added successfully!")
            // }
        }
        catch (e) {
            const message = e && e.data && e.data.message ? e.data.message : "An error occurred. Please try again!";
            toastError(message)
        }
        finally {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const removeSubSection = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            const resp = await removeCustomSubSectionService(templateId, subSectionInfo.parentId, subSectionInfo.categoryId);
            if (resp && resp.success) {
                getTemplateInfo();
                window.$("#removeCustomSubSectionModal").modal("toggle");
                toastSuccess("Section removed successfully!")
            }
        }
        catch (e) {
            const message = e && e.data && e.data.message ? e.data.message : "An error occurred. Please try again!";
            toastError(message)
        }
        finally {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const addNewSubSectionContent = () => {
        return (
            <div className="modal fade" id="addSubSectionModal" tabIndex="-1" role="dialog" aria-labelledby="addSubSectionModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addSubSectionModalLabel">Add New Section</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Key Name:</label>
                                            <input type="text" className="form-control" value={subSectionKey} onChange={(e) => setSubSectionKey(e.target.value)} placeholder="Enter key filed name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Prompt:</label>
                                            <textarea rows={8} className="form-control" value={subSectionPrompt} onChange={(e) => setSubSectionPrompt(e.target.value)} placeholder="Enter prompts" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => addNewSubSection(e)}>Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const removeCustomSubSectionModalContent = () => {
        return (
            <div className="modal fade" id="removeCustomSubSectionModal" tabIndex="-1" role="dialog" aria-labelledby="removeCustomSubSectionModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="removeCustomSubSectionModalLabel">Delete Section</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure, you want to remove section <b>"{subSectionInfo.name}"</b>?
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => removeSubSection(e)}>Yes, Delete</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const changeSection = (direction) => {
        const currentIndex = sections.findIndex((section) => section.selected);
        let newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;

        if (newIndex >= 0 && newIndex < sections.length) {
            const updatedSections = sections.map((section, index) => ({
                ...section,
                selected: index === newIndex,
            }));
            changeSelectedSection(updatedSections[newIndex], newIndex)
            setSelectedSection(updatedSections[newIndex].categoryId)
        }
    };

    const addNewSectionContent = () => {
        return (
            <div className="modal fade" id="addNewSectionModal" tabIndex="-1" role="dialog" aria-labelledby="addNewSectionModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addNewSectionModalLabel">Add New Section</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Section Name:</label>
                                            <input type="text" className="form-control" value={sectionName} onChange={(e) => setSectionName(e.target.value)} placeholder="Enter section name" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => addNewSection(e)}>Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="d-inline-flex flex-row bl-btn-grp-fixed bl-right-unset">
                    <button onClick={() => nav('/dealbook/templates')} className="d-sm-inline-block btn btn-sm btn-outline-primary"><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                </div>
                {
                    sections && sections.length ? (
                        <div className="d-inline-flex flex-row bl-btn-grp-fixed">
                            {selectedSection !== sections[0].categoryId && (
                                <button
                                    className="d-sm-inline-block btn btn-sm btn-primary mr-2"
                                    onClick={() => changeSection("previous")}
                                >
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                                    {buttonsLabel.PREVIOUS}
                                </button>
                            )}

                            {selectedSection !== sections[sections.length - 1].categoryId && (
                                <button
                                    className="d-sm-inline-block btn btn-sm btn-primary mr-2"
                                    onClick={() => changeSection("next")}
                                >
                                    <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                                    {buttonsLabel.SAVE_PROCEED}
                                </button>
                            )}

                            {selectedSection === sections[sections.length - 1].categoryId && (
                                <button
                                    className="d-sm-inline-block btn btn-sm btn-primary mr-2"
                                    onClick={updateTemplateList}
                                >
                                    <i className="fa fa-floppy-o mr-1" aria-hidden="true"></i>{" "}
                                    {buttonsLabel.SAVE_PUBLISH}
                                </button>
                            )}
                        </div>
                    ) : null
                }

                {/* <div className={"d-inline-flex flex-row bl-btn-grp-fixed"}>
                    <button className="d-sm-inline-block btn btn-sm btn-primary mr-2" onClick={() => updateTemplateList()}><i className="fa fa-floppy-o mr-1" aria-hidden="true"></i> {buttonsLabel.SAVE}</button>
                </div> */}

                <div className="row" id="bio">
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <div className="sidebar-brand-icon mb-2">
                            <img src={testIcon} height="70px" />
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <h4 className="h4 text-gray-800">{action === "NEW" ? templateLabel.TITLE : templateLabel.TITLE_UPDATE}</h4>
                    </div>


                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <p>{action === "NEW" ? templateLabel.DESCRIPTION : templateLabel.DESCRIPTION_UPDATE}</p>
                    </div>
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <div className="d-flex py-3">
                            {
                                sections && sections.map((sec, i) => {
                                    return (
                                        <span key={`sections-badge-${i}`} className={`badge bl-sb-datatableTabs bl-chip-btn cp mr-2 ${sec.selected ? 'active' : ''}`} onClick={() => changeSelectedSection(sec, i)}>{sec.name}</span>
                                    )
                                })
                            }
                            {
                                action === "NEW" ? <div className="border-left pl-2">
                                    <span className={`badge bl-sb-datatableTabs bl-chip-btn cp`} data-toggle="modal" data-target="#addNewSectionModal"><i className="fa fa-plus" aria-hidden="true"></i></span>
                                </div> : null
                            }
                        </div>
                    </div>

                    {
                        isLoading ? <div className="col text-center">
                            <img className="bl-list-loader" src={listViewLoader} />
                        </div> : (
                            <>
                                <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                                    <div className="card-body">
                                        <h5 className="card-title">{templateLabel.FORMS.BASIC.TITLE}</h5>
                                        <p className="card-text text-muted">{action === "NEW" ? templateLabel.FORMS.BASIC.DESCRIPTION : templateLabel.FORMS.BASIC.DESCRIPTION}</p>
                                    </div>
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td className="w-25"><div className="text-small pt-2 pl-2">{templateLabel.FORMS.BASIC.TEMPLATE_NAME}</div></td>
                                                <td width="40%">
                                                    <input type="text" readOnly value={templateName} onChange={(e) => setTemplateName(e.target.value)} className="form-control" id="planename" placeholder="" />
                                                </td>
                                                <td>
                                                    <small id="passwordHelpInline" className="text-muted">
                                                        {templateLabel.FORMS.BASIC.TEMPLATE_VALID}
                                                    </small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><div className="text-small pt-2 pl-2">{templateLabel.FORMS.BASIC.TEMPLATE_DESCRIPTION}</div></td>
                                                <td>
                                                    <textarea type="text" readOnly value={templateDescription} onChange={(e) => setTemplateDescription(e.target.value)} className="form-control" id="thresholdpercentage" placeholder="" />
                                                </td>
                                                <td>
                                                    <small id="passwordHelpInline" className="text-muted">
                                                        {templateLabel.FORMS.BASIC.TEMPLATE_DESCRIPTION_VALID}
                                                    </small>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                                    <div className="card-body d-flex justify-content-between align-items-end">
                                        <div>
                                            <h5 className="card-title">{templateLabel.FORMS.SECTION.TITLE}</h5>
                                            <p className="card-text text-muted">{action === "NEW" ? templateLabel.FORMS.SECTION.DESCRIPTION : templateLabel.FORMS.SECTION.DESCRIPTION}</p>
                                        </div>
                                        {
                                            !isScorecardSection ? <button className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#addSubSectionModal" onClick={() => clearSubSectionForm()}><i className="fa fa-plus-circle mr-2" aria-hidden="true"></i>Add New Section</button> : null
                                        }

                                    </div>
                                    {
                                        subSections && subSections.map((secInfo, index) => {
                                            const canHeaderVisible = secInfo && secInfo.prompt && Array.isArray(secInfo.prompt);
                                            return (
                                                canHeaderVisible ? (
                                                    <div key={`category-sec-${index}`} className="card rounded-0">
                                                        <div className="card-header">
                                                            <h6 className="mb-0">{secInfo.name}</h6>
                                                        </div>
                                                        <div className="card-body p-0">
                                                            <table className="table table-bordered mb-0">
                                                                <tbody>
                                                                    {
                                                                        secInfo.prompt.map((info, j) => {
                                                                            return (
                                                                                <tr key={`template-key-prompt-sec-${index}${j}`}>
                                                                                    <td valign="top" className="w-25">
                                                                                        <div className="text-small pt-2 pl-2">{info.name}</div>
                                                                                        {/* <input type="text" name="key" value={info.name} onChange={(e) => setTemplateKey(e.target.value, j)} onBlur={() => updateTemplateList(info)} className="form-control" placeholder={templateLabel.FORMS.SECTION.KEY_PLACEHOLDER} /> */}
                                                                                    </td>
                                                                                    <td>
                                                                                        <textarea name={`prompt header ${secInfo.categoryId} ${index} ${j}`} className="form-control form-control-sm border-0" rows={8} defaultValue={info.prompt} onChange={(e) => handleInputChange(index, j, e.target.value)}></textarea>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    < table key={`template-table-${index}`} className={`table table-bordered mb-0`}>
                                                        <tbody>
                                                            <tr key={`template-key-prompt-sec-${index}`}>
                                                                <td valign="top" className="w-25">
                                                                    <div className="text-small pt-2 pl-2">{secInfo.name}</div>
                                                                    {/* <input type="text" name="key" value={secInfo.name} onChange={(e) => setTemplateKey(e.target.value, index)} onBlur={() => updateTemplateList(secInfo)} className="form-control" placeholder={templateLabel.FORMS.SECTION.KEY_PLACEHOLDER} /> */}
                                                                </td>
                                                                <td className="border-0">
                                                                    <textarea className="form-control form-control-sm border-0" id={`prompt${secInfo.categoryId}${index}`} name={`prompt ${secInfo.categoryId} ${index}`} rows={8} defaultValue={secInfo.prompt} onChange={(e) => handleInputChange(index, undefined, e.target.value)}></textarea>
                                                                </td>
                                                                <td valign="top" className="border-0" width={'20px'}>
                                                                    {
                                                                        secInfo.customSection ? <button className="btn btn-circle btn-outline-primary btn-sm mr-2" data-toggle="modal" data-target="#removeCustomSubSectionModal" onClick={() => setSubSectionInfo(secInfo)}><i className="fa fa-trash" aria-hidden="true"></i></button> : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                )

                                            )
                                        })
                                    }
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
            {addNewSectionContent()}
            {addNewSubSectionContent()}
            {removeCustomSubSectionModalContent()}
        </div >
    )
}