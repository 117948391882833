import apiInstance from "./serviceMaster";

const testPlanURL = "test/plan";
const allTestPlanURL = "test/all/plan";
const runTestURL = "test/run";

export const createTestPlanService = async(obj) => {
    try{
        return await apiInstance.post(testPlanURL, obj)
    }
    catch(e){
        return e;
    }
}

export const updateTestPlanService = async(obj) => {
    try{
        return await apiInstance.post('test/update/plan', obj)
    }
    catch(e){
        return e;
    }
}

export const updateTestPlanCaseService = async(obj) => {
    try{
        return await apiInstance.post(`${testPlanURL}/case`, obj)
    }
    catch(e){
        return e;
    }
}

export const deleteTestPlanCasesService = async(obj) => {
    try{
        return await apiInstance.post(`${testPlanURL}/cases`, obj)
    }
    catch(e){
        return e;
    }
}

export const getAllTestPlanService = async(obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${allTestPlanURL}${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export const getAllTestPlanRunHistoryService = async(obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${testPlanURL}${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export const runTestPlanService = async(obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${runTestURL}${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export const testJobStatusService = async(id) => {
    try{
        return await apiInstance.get(`${runTestURL}/${id}`)
    }
    catch(e){
        return e;
    }
}

export const getTestPlanLastSummaryService = async(obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${testPlanURL}/summary${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export const getTestPlanInfoService = async(obj, planId) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${testPlanURL}/${planId}${queryParams}`)
    }
    catch(e){
        return e;
    }
}
